<template>
  <div class="page" id="survey-cdrs">
    <div class="card p-4 filters mb-2">
      <div class="row mb-2">
        <div class="col-md-4">
          <Field :label="$t('call-survey.survey-cdrs.filters.phone.label')">
            <b-form-input
              v-model="filters.phone"
              class="border"
              :placeholder="
                $t('call-survey.survey-cdrs.filters.phone.placeholder')
              "
              type="number"
              @keydown="onlyAcceptNumbers"
            />
          </Field>
        </div>
        <div class="col-md-4">
          <Field :label="$t('call-survey.survey-cdrs.filters.status.label')">
            <Select
              class="select"
              multiple
              searchable
              v-model="filters.statuses"
              :placeholder="
                $t('call-survey.survey-cdrs.filters.status.placeholder')
              "
              :options="statusOptions"
              :preselectFirst="false"
            />
          </Field>
        </div>
        <div class="col-md-4">
          <Field
            :label="
              $t('call-survey.survey-cdrs.filters.completion-status.label')
            "
          >
            <Select
              class="select"
              multiple
              searchable
              v-model="filters.completionStatuses"
              :placeholder="
                $t(
                  'call-survey.survey-cdrs.filters.completion-status.placeholder'
                )
              "
              :options="completionStatusOptions"
              :preselectFirst="false"
            />
          </Field>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" class="px-3 py-2" @click="onFilter">
          {{ $t('call-survey.survey-cdrs.filter') }}
        </b-button>
      </div>
    </div>
    <data-table
      ref="surveyCallRequestsTable"
      :data="cdrs"
      :fields="translatedTableCdrsFields"
      :pagination="pagination"
      :loading="isLoading"
      @changePage="onPageChange($event)"
    >
      <template v-slot:status="{ row }">
        <b-badge
          class="px-3 py-2"
          :style="{
            backgroundColor: cdrsStatusColors[row.status] || '#ccc'
          }"
        >
          {{ $t(`call-survey.survey-cdrs.statusOptions.${row.status}`) }}
        </b-badge>
      </template>
      <template v-slot:completionStatus="{ row }">
        <b-badge
          class="px-3 py-2"
          :style="{
            backgroundColor:
              cdrsCompletionStatusColors[row.completionStatus] || '#ccc'
          }"
        >
          {{
            $t(
              `call-survey.survey-cdrs.completionStatusOptions.${row.completionStatus}`
            )
          }}
        </b-badge>
      </template>
      <template v-slot:recordingLink="{ row }">
        <b-button
          size="sm"
          variant="outline-primary"
          class="px-3 py-2"
          v-if="row.recordingLink"
          @click="
            currentRecordingLink = row.recordingLink
            showRecordingModal = true
          "
        >
          {{ $t('call-survey.survey-cdrs.table.listen') }}
        </b-button>
      </template>
      <template v-slot:agentConnectedAt="{ row }">
        {{ row.agentConnectedAt | formatDate }}
      </template>
      <template v-slot:customerConnectedAt="{ row }">
        {{ row.customerConnectedAt | formatDate }}
      </template>
      <template v-slot:actions="{ row }">
        <div class="d-flex" v-if="row.completionStatus !== 'no-response'">
          <b-button
            size="sm"
            variant="outline-primary"
            class="px-3 py-2"
            @click="
              showAnswersModal = true
              currentCDR = row
            "
          >
            {{ $t('call-survey.survey-cdrs.table.answers') }}
          </b-button>
        </div>
      </template>
    </data-table>

    <b-modal
      :title="$t('auto-dialer.campaign-cdrs.modals.recording.title')"
      v-model="showRecordingModal"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      :dir="dir"
      ignore-enforce-focus-selector="textarea, button, input"
    >
      <audio class="w-100" controls :src="currentRecordingLink"></audio>
    </b-modal>

    <b-modal
      :title="$t('call-survey.survey-cdrs.modals.answers.title')"
      v-model="showAnswersModal"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
      :dir="dir"
    >
      <div class="answers">
        <!-- <div class="answer-bar mb-3">
          <p class="my-0">
            <small>
              {{ $t('call-survey.survey-cdrs.modals.answers.answers-time') }}
            </small>
          </p>
          <p class="my-0">
            <small>{{ currentCDR.duration }}</small>
          </p>
        </div> -->
        <div
          class="answer mb-3 card p-2"
          v-for="(answer, answerIdx) in currentCDR.answers"
          :key="answerIdx"
        >
          <h5 class="m-0 mb-1">
            {{ $t('call-survey.survey-cdrs.modals.answers.question') }}
            {{ answer.questionIndex + 1 }}
          </h5>
          <p class="m-0 mb-1">
            <b
              >{{
                $t('call-survey.survey-cdrs.modals.answers.attempt-number')
              }}: </b
            >{{ answer.attemptNumber }}
          </p>
          <p class="m-0 mb-1">
            <b
              >{{ $t('call-survey.survey-cdrs.modals.answers.question-type') }}:
            </b>
            {{ questionTypes[answer.questionType] }}
          </p>
          <!-- <div class="answer-bar">
            <p class="my-0">
              <small>
                {{ $t('call-survey.survey-cdrs.modals.answers.response-time') }}
                :</small
              >
            </p>
            <p class="my-0">
              <small>{{ currentCDR.duration }}</small>
            </p>
          </div> -->

          <div class="user-response">
            <p class="my-0">
              <small>
                {{ $t('call-survey.survey-cdrs.modals.answers.user-response') }}
                :</small
              >
            </p>
            <b-button
              v-if="answer.questionType === 'yes_no'"
              class="px-3 py-2 mb-2"
              variant="warning"
              :style="{
                color: 'white',
                backgroundColor:
                  parseInt(answer.userResponse) === 1 ? '#66BB6A' : '#FF6B6B'
              }"
            >
              {{ parseInt(answer.userResponse) === 1 ? 'Yes' : 'No' }}
            </b-button>

            <b-form-rating
              v-else-if="answer.questionType === 'one_five'"
              :value="+answer.userResponse"
              variant="warning"
              class="px-2 mb-2"
              readonly
              size="lg"
              inline
              show-value
            ></b-form-rating>

            <b-button
              v-else
              class="px-3 py-2 mb-2"
              variant="warning"
              :style="{
                color: 'white',
                backgroundColor: '#888'
              }"
            >
              {{ answer.userResponse }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Field from '@/elements/Field.vue'
import { onlyAcceptNumbers } from '@/utils/utils'
import CallSurveyService from '../../../services/call-survey.service'
import DataTable from '../../../elements/Table.vue'
import moment from 'moment'

export default {
  name: 'SurveyCdrs',
  components: {
    Field,
    DataTable
  },
  data() {
    return {
      filters: {
        statuses: [],
        phone: '',
        completionStatuses: []
      },
      pagination: {
        page: 1,
        limit: 10
      },
      cdrsStatusColors: {
        'User did not answer': '#FF6B6B', // Bright Red
        'Airplane Mode Enabled': '#FFB74D', // Vibrant Orange
        Initiated: '#FFC107', // Golden Yellow
        Processing: '#66BB6A', // Fresh Green
        'Call Failed': '#D32F2F', // Deep Red
        'User Busy/Rejected By User': '#FF8A65', // Soft Coral
        'User Connected': '#43A047', // Strong Green
        'User Unreachable (Out of Network Coverage or Airplane Mode)':
          '#FF9800', // Bright Orange
        Completed: '#5C6BC0', // Rich Blue
        Timeout: '#E53935', // Intense Red
        Abandoned: '#F4511E' // Bold Orange-Red
      },
      cdrsCompletionStatusColors: {
        complete: '#66BB6A', // Fresh Green
        partial: '#0F6B6B', // Dark Blue
        'no-response': '#FF6B6B' // Bright Red
      },
      progressBarColors: [
        '#FF6B6B', // Bright Red
        '#FFB74D', // Vibrant Orange
        '#66BB6A', // Fresh Green
        '#D32F2F', // Deep Red
        '#FF8A65', // Soft Coral
        '#43A047', // Strong Green
        '#FF9800', // Bright Orange
        '#E53935', // Intense Red
        '#5C6BC0', // Rich Blue
        '#F4511E', // Bold Orange-Red
        '#0F6B6B' // Dark Blue
      ],
      questionTypes: {
        one_five: '1 - 5',
        one_ten: '1 - 10',
        zero_nine: '1 - 10',
        yes_no: 'yes or no'
      },
      cdrs: [],
      isLoading: false,
      showRecordingModal: false,
      showAnswersModal: false,
      currentCDR: {},
      currentRecordingLink: ''
    }
  },
  methods: {
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    durationToSeconds(duration) {
      // 00:32
      return moment.duration(`00:${duration}`).asSeconds()
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCdrs()
    },
    onFilter() {
      this.pagination.page = 1
      this.fetchCdrs()
    },
    async fetchCdrs() {
      try {
        this.isLoading = true
        const cdrs = await CallSurveyService.fetchSurveyCdrs(
          this.surveyId,
          {
            ...this.filters,
            statuses: this.filters.statuses.map(status => status.id),
            completionStatuses: this.filters.completionStatuses.map(
              status => status.id
            )
          },
          this.pagination.page,
          10
        )
        const { callRequests, ...pagination } = cdrs.data
        this.cdrs = callRequests
        this.pagination = { ...this.pagination, ...pagination }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    }
  },
  computed: {
    surveyId() {
      return this.$route.params.id
    },
    dir() {
      return this.$i18n.locale() === 'ar' ? 'rtl' : 'ltr'
    },
    statusOptions() {
      return [
        {
          id: 'User did not answer',
          name: this.$t(
            'call-survey.survey-cdrs.statusOptions.User did not answer'
          )
        },
        {
          id: 'Airplane Mode Enabled',
          name: this.$t(
            'call-survey.survey-cdrs.statusOptions.Airplane Mode Enabled'
          )
        },
        {
          id: 'Call Failed',
          name: this.$t('call-survey.survey-cdrs.statusOptions.Call Failed')
        },
        {
          id: 'User Busy/Rejected By User',
          name: this.$t(
            'call-survey.survey-cdrs.statusOptions.User Busy/Rejected By User'
          )
        },
        {
          id: 'User Unreachable (Out of Network Coverage or Airplane Mode)',
          name: this.$t(
            'call-survey.survey-cdrs.statusOptions.User Unreachable (Out of Network Coverage or Airplane Mode)'
          )
        },
        {
          id: 'Completed',
          name: this.$t('call-survey.survey-cdrs.statusOptions.Completed')
        },
        {
          id: 'Timeout',
          name: this.$t('call-survey.survey-cdrs.statusOptions.Timeout')
        },
        {
          id: 'Abandoned',
          name: this.$t('call-survey.survey-cdrs.statusOptions.Abandoned')
        }
      ]
    },
    completionStatusOptions() {
      return [
        {
          id: 'complete',
          name: this.$t(
            'call-survey.survey-cdrs.completionStatusOptions.complete'
          )
        },
        {
          id: 'partial',
          name: this.$t(
            'call-survey.survey-cdrs.completionStatusOptions.partial'
          )
        },
        {
          id: 'no-response',
          name: this.$t(
            'call-survey.survey-cdrs.completionStatusOptions.no-response'
          )
        }
      ]
    },
    translatedTableCdrsFields() {
      return [
        {
          accessor: 'phone',
          header: this.$t('call-survey.survey-cdrs.table.columns.phone')
        },
        {
          accessor: 'name',
          header: this.$t('call-survey.survey-cdrs.table.columns.name')
        },
        {
          accessor: 'status',
          header: this.$t('call-survey.survey-cdrs.table.columns.status')
        },
        {
          accessor: 'initiatedAt',
          header: this.$t('call-survey.survey-cdrs.table.columns.initiatedAt')
        },
        {
          accessor: 'completionStatus',
          header: this.$t(
            'call-survey.survey-cdrs.table.columns.completionStatus'
          )
        },
        {
          accessor: 'answeredQuestionsCount',
          header: this.$t(
            'call-survey.survey-cdrs.table.columns.answeredQuestionsCount'
          )
        },
        {
          accessor: 'duration',
          header: this.$t('call-survey.survey-cdrs.table.columns.duration')
        },
        // {
        //   accessor: 'recordingLink',
        //   header: this.$t('call-survey.survey-cdrs.table.columns.recordingLink')
        // },
        {
          accessor: 'actions',
          header: this.$t('call-survey.survey-cdrs.table.columns.actions')
        }
      ]
    }
  },
  mounted() {
    this.fetchCdrs()
  }
}
</script>

<style lang="scss">
.answer-bar,
.user-response {
  display: flex;
  align-items: center;
  gap: 1rem;
}
</style>
