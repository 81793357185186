import http from '../../axios'
import store from '../../store'

export default {
  state: {
    detailRowsDataDay: {},
    detailRowsDataMonth: {},
    detailRowsStatus: {},
    allExtensions: [],
    pbxUrl: null
  },
  getters: {
    detailRowsDataDay(state) {
      return state.detailRowsDataDay
    },
    detailRowsDataMonth(state) {
      return state.detailRowsDataMonth
    },
    detailRowsStatus(state) {
      return state.detailRowsStatus
    },
    allExtensions(state) {
      return state.allExtensions
    },
    pbxUrl(state) {
      return state.pbxUrl
    }
  },
  mutations: {
    addDetailRowDataDay(state, { id, data }) {
      state.detailRowsDataDay[id] = data
    },
    addDetailRowDataMonth(state, { id, data }) {
      state.detailRowsDataMonth[id] = data
    },
    addDetailRowStatus(state, { id, data }) {
      state.detailRowsStatus[id] = data
    },
    setExtensions(state, data) {
      state.allExtensions = data
    },
    setPbxUrl(state, val) {
      state.pbxUrl = val
    }
  },
  actions: {
    async createExtension({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(
          `${process.env.VUE_APP_NEW_API}extension`,
          {
            ...payload,
            ext: +payload.ext,
            inbound: +payload.inbound,
            outbound: +payload.outbound,
            voicemail: +payload.voicemail
          }
        )
        return {
          success: true,
          data: response.data
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.message)
          return {
            success: false,
            errors: { ...error.response.data.message }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchLastExtensionNumber({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}extension/recommend-number`
        )
        return response.data
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchExtension({ commit }, id) {
      commit('setError', null)
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}extension/show/${id}`
        )
        return {
          success: true,
          data: response.data
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async updateExtension({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.put(
          `${process.env.VUE_APP_NEW_API}extension`,
          {
            ...payload,
            inbound: +payload.inbound,
            outbound: +payload.outbound,
            voicemail: +payload.voicemail
          }
        )
        return {
          success: true,
          data: { ...response.data.data }
        }
      } catch (error) {
        commit('setError', error.response.data.message)
        return {
          success: false,
          errors: { ...error.response.data.message }
        }
      }
    },
    async resetExtensionPassword({ commit }, extensionId) {
      commit('setError', null)
      try {
        const response = await http.post(
          `${process.env.VUE_APP_NEW_API}extension/reset-password`,
          {
            extensionId: +extensionId
          }
        )
        return {
          success: true,
          data: { ...response.data }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchDetailRowDataDay({ commit }, id) {
      commit('setError', null)
      try {
        const response = await http.get(
          `sip/provisioningwa/api/v2/tenant/extension/online/data/today?extension_id=${id}`
        )

        if (response.data.success) {
          commit('addDetailRowDataDay', {
            id: id,
            data: response.data.data
          })
          return true
        } else {
          commit('setError', response.data.error.message)
          commit('setReloadTabledata', true)
          return false
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          commit('setReloadTabledata', true)
          return false
        } else {
          commit('setError', error.message)
          commit('setReloadTabledata', true)
          return false
        }
      }
    },
    async fetchDetailRowDataMonth({ commit }, id) {
      commit('setError', null)
      try {
        const response = await http.get(
          `sip/provisioningwa/api/v2/tenant/extension/online/data/month?extension_id=${id}`
        )

        if (response.data.success) {
          commit('addDetailRowDataMonth', {
            id: id,
            data: response.data.data
          })
          return true
        } else {
          commit('setError', response.data.error.message)
          commit('setReloadTabledata', true)
          return false
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          commit('setReloadTabledata', true)
          return false
        } else {
          commit('setError', error.message)
          commit('setReloadTabledata', true)
          return false
        }
      }
    },
    async fetchDetailRowStatus({ commit }, id) {
      commit('setError', null)
      try {
        const response = await http.get(
          `sip/provisioningwa/api/v2/tenant/extension/online/active?extension_id=${id}`
        )
        if (response.data.success) {
          commit('addDetailRowStatus', {
            id: id,
            data: response.data.data
          })
          return true
        } else {
          commit('setError', response.data.error.message)
          commit('setReloadTabledata', true)
          return false
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          commit('setReloadTabledata', true)
          return false
        } else {
          commit('setError', error.message)
          commit('setReloadTabledata', true)
          return false
        }
      }
    },
    async sendCredentialsEmail({ commit }, payload) {
      commit('setError', null)
      try {
        const response = await http.post(
          `${process.env.VUE_APP_NEW_API}extension/send-main-information`,
          {
            ...payload
          }
        )
        return {
          success: true,
          data: { ...response.data }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async toggleExtensionStatus({ commit }, extension) {
      commit('setError', null)
      try {
        const data = {
          extensionId: +extension.id,
          activeStatus: +!(extension.status === 'enabled')
        }
        const response = await http.patch(
          `${process.env.VUE_APP_NEW_API}extension/active-status`,
          data
        )
        if (response.data.success) {
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchAllExtensions({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `${process.env.VUE_APP_NEW_API}extension/list`
        )
        commit('setExtensions', response.data)
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return false
        } else {
          commit('setError', error.message)
          return false
        }
      }
    },
    async checkExtensionAvailability({ commit }, id) {
      commit('setError', null)
      try {
        const response = await http.get(
          `sip/provisioningwa/api/v2/tenant/extension/deleted/checker?extension_id=${id}`
        )
        if (response.data.success) {
          return {
            success: true,
            data: response.data.data
          }
        } else {
          commit('setError', response.data.error.message)
          return {
            success: false,
            errors: response.data.error.errors
          }
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: {}
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    async fetchPBXUrl({ commit }) {
      commit('setError', null)
      try {
        const response = await http.get(
          `sip/provisioningwa/api/v1/information/pbx-url`
        )
        if (response.data.success) {
          commit('setPbxUrl', response.data.data.url)
          return {
            success: true,
            data: { ...response.data.data }
          }
        } else {
          commit('setError', response.data.error.message)
        }
      } catch (error) {
        if (error.response) {
          commit('setError', error.response.data.error.message)
          return {
            success: false,
            errors: { ...error.response.data.error.errors }
          }
        } else {
          commit('setError', error.message)
          return {
            success: false,
            errors: {}
          }
        }
      }
    },
    setExtensions({ commit }, payload) {
      commit('setExtensions', payload)
    }
  }
}
