<template>
  <div class="page" id="auto-dialer-list">
    <div class="mb-2 p-3 card header">
      <tabs
        :options="stateOptions"
        :value="filters.state"
        @input="onIsActiveChange"
      ></tabs>
      <router-link to="/auto-dialer/create" class="px-4 py-3 btn btn-primary">
        {{ $t('auto-dialer.list.create') }}
      </router-link>
    </div>

    <div class="content">
      <data-table
        ref="campaigns"
        :data="campaigns"
        :fields="translatedTableFields"
        :loading="isLoading"
        :pagination="pagination"
        @changePage="onPageChange"
      >
        <template v-slot:status="{ row }">
          <status-cell :rowData="row" />
        </template>
        <template v-slot:actions="{ row }">
          <actions-cell
            :rowData="row"
            @refetch="fetchAutoDialerCampaigns({ loading: true })"
            @showMetrics="onShowMetrics($event, row.id)"
            @showInitiatedCalls="onShowInitiatedCalls($event, row.id)"
          />
        </template>
      </data-table>
    </div>

    <b-modal
      id="modal-xl"
      size="xl"
      :title="$t('auto-dialer.campaign-metrics.title')"
      v-model="showMetrics"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
    >
      <CampaignMetrics :metrics="currentMetrics" :campaignId="campaignId" />
    </b-modal>

    <b-modal
      id="modal-initiated-calls"
      size="xl"
      :title="$t('auto-dialer.initiated-calls.title')"
      v-model="showInitiatedCalls"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
    >
      <CampaignInitiatedCalls
        :initiatedCalls="currentInitiatedCalls"
        :campaignId="campaignId"
      />
    </b-modal>
  </div>
</template>

<script>
import DataTable from '../../../elements/Table.vue'
import Tabs from '../../../elements/Tabs.vue'
import AutoDialerService from '../../../services/autoDialer.service'
import ActionsCell from './cells/ActionsCell.vue'
import StatusCell from './cells/StatusCell.vue'
import CampaignMetrics from './campaign-metrics.vue'
import CampaignInitiatedCalls from './campaign-initiated-calls.vue'

let interval

export default {
  components: {
    DataTable,
    ActionsCell,
    StatusCell,
    CampaignMetrics,
    Tabs,
    CampaignInitiatedCalls
  },
  data() {
    return {
      campaigns: [],
      isLoading: false,
      tableFields: [
        {
          accessor: 'name',
          header: 'auto-dialer.list.table.name'
        },
        {
          accessor: 'status',
          header: 'auto-dialer.list.table.status'
        },
        {
          accessor: 'actions',
          header: 'auto-dialer.list.table.actions',
          width: '40%'
        }
      ],
      pagination: {
        totalPages: 0,
        page: 1
      },
      campaignId: null,
      currentMetrics: {},
      currentInitiatedCalls: [],
      showMetrics: false,
      showInitiatedCalls: false,
      filters: {
        state: 'active'
      }
    }
  },
  computed: {
    translatedTableFields() {
      return this.tableFields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    },
    stateOptions() {
      return [
        {
          title: this.$t('auto-dialer.list.states.active'),
          value: 'active'
        },
        {
          title: this.$t('auto-dialer.list.states.finished'),
          value: 'finished'
        }
      ]
    }
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.fetchAutoDialerCampaigns({ loading: true })
      this.$router.push({ query: { page } })
    },
    async fetchAutoDialerCampaigns({ loading = true }) {
      if (loading) this.isLoading = true
      try {
        const res = await AutoDialerService.listCampaigns(
          {
            isActive: this.filters.state === 'active'
          },
          this.pagination.page
        )
        const { campaigns, ...pagination } = res.data
        this.campaigns = campaigns
        this.pagination = {
          ...this.pagination,
          ...pagination
        }
      } catch (error) {
      } finally {
        this.isLoading = false
      }
    },
    onShowMetrics(metrics, campaignId) {
      this.currentMetrics = metrics
      this.showMetrics = true
      this.campaignId = campaignId
    },
    onShowInitiatedCalls(initiatedCalls, campaignId) {
      this.currentInitiatedCalls = initiatedCalls.data.calls
      this.showInitiatedCalls = true
      this.campaignId = campaignId
    },
    onIsActiveChange(value) {
      this.filters.state = value
      this.onPageChange(1)
    }
  },
  mounted() {
    this.pagination.page = this.$route.query.page || 1
    this.fetchAutoDialerCampaigns({ loading: true })
    interval = setInterval(() => {
      this.fetchAutoDialerCampaigns({ loading: false })
    }, 10000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: spin 1s linear infinite;
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
</style>
