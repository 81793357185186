<template>
  <div class="page" id="campaign-cdrs">
    <div class="mb-2 head">
      <div class="d-flex align-items-center mb-2 filters">
        <Field :label="$t('auto-dialer.campaign-cdrs.filters.status.label')">
          <Select
            class="select"
            multiple
            searchable
            v-model="filters.statuses"
            :placeholder="
              $t('auto-dialer.campaign-cdrs.filters.status.placeholder')
            "
            :options="statusOptions"
            :preselectFirst="false"
          />
        </Field>
        <Field :label="$t('auto-dialer.campaign-cdrs.filters.agents.label')">
          <Select
            class="select"
            multiple
            searchable
            v-model="filters.agents"
            :placeholder="
              $t('auto-dialer.campaign-cdrs.filters.agents.placeholder')
            "
            :options="assignedAgents"
            :preselectFirst="false"
          />
        </Field>
        <Field :label="$t('auto-dialer.campaign-cdrs.filters.phone.label')">
          <b-form-input
            class="border"
            v-model="filters.phone"
            :placeholder="
              $t('auto-dialer.campaign-cdrs.filters.phone.placeholder')
            "
            type="number"
            @keydown="onlyAcceptNumbers"
          />
        </Field>
      </div>
      <div class="d-flex justify-content-end filters-actions">
        <b-button
          variant="outline-primary"
          @click="clearFilters()"
          class="px-3 py-2"
          :disabled="isLoading"
        >
          <i class="fa fa-times"></i>
          {{ $t('auto-dialer.campaign-cdrs.clear-filter') }}
        </b-button>
        <b-button
          variant="primary"
          @click="OnFilterUpdate"
          class="px-3 py-2"
          :disabled="isLoading"
        >
          {{ $t('auto-dialer.campaign-cdrs.filter') }}
        </b-button>
      </div>
    </div>
    <data-table
      ref="campaignCallRequestsTable"
      :data="cdrs"
      :fields="translatedTableCdrsFields"
      :pagination="pagination"
      :loading="isLoading"
      @changePage="onPageChange($event)"
    >
      <template v-slot:status="{ row }">
        <b-badge
          class="px-3 py-2"
          :style="{
            backgroundColor: cdrsStatusColors[row.status] || '#ccc'
          }"
        >
          {{ $t(`auto-dialer.campaign-cdrs.statusOptions.${row.status}`) }}
        </b-badge>
      </template>
      <template v-slot:recordingLink="{ row }">
        <b-button
          size="sm"
          variant="outline-primary"
          class="px-3 py-2"
          v-if="row.recordingLink"
          @click="
            currentRecordingLink = row.recordingLink
            showRecordingModal = true
          "
        >
          {{ $t('auto-dialer.campaign-cdrs.table.listen') }}
        </b-button>
      </template>
      <template v-slot:agentConnectedAt="{ row }">
        {{ row.agentConnectedAt | formatDate }}
      </template>
      <template v-slot:customerConnectedAt="{ row }">
        {{ row.customerConnectedAt | formatDate }}
      </template>
    </data-table>

    <b-modal
      :title="$t('auto-dialer.campaign-cdrs.modals.recording.title')"
      v-model="showRecordingModal"
      scrollable
      content-class="overflow-auto"
      hide-footer
      centered
      ignore-enforce-focus-selector="textarea, button, input"
    >
      <audio class="w-100" controls :src="currentRecordingLink"></audio>
    </b-modal>
  </div>
</template>

<script>
import AutoDialerService from '../../../services/autoDialer.service'
import DataTable from '../../../elements/Table.vue'
import moment from 'moment'
import Field from '../../../elements/Field.vue'
import ExtensionService from '../../../services/extension.service'
import { onlyAcceptNumbers } from '../../../utils/utils'

export default {
  name: 'campaign-cdrs',
  components: {
    DataTable,
    Field
  },
  data() {
    return {
      isLoading: false,
      cdrs: [],
      filters: {
        statuses: [],
        phone: '',
        agents: []
      },
      assignedAgents: [],
      extensions: [],
      cdrsStatusColors: {
        'User did not answer': '#FF6B6B', // Bright Red
        'Airplane Mode Enabled': '#FFB74D', // Vibrant Orange
        Initiated: '#FFC107', // Golden Yellow
        Processing: '#66BB6A', // Fresh Green
        'Call Failed': '#D32F2F', // Deep Red
        'User Busy/Rejected By User': '#FF8A65', // Soft Coral
        'User Connected': '#43A047', // Strong Green
        'User Unreachable (Out of Network Coverage or Airplane Mode)':
          '#FF9800', // Bright Orange
        Completed: '#5C6BC0', // Rich Blue
        Timeout: '#E53935', // Intense Red
        Abandoned: '#F4511E' // Bold Orange-Red
      },
      pagination: {
        page: 1,
        totalPages: 0
      },
      currentRecordingLink: '',
      showRecordingModal: false
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.id
    },
    translatedTableCdrsFields() {
      return [
        {
          accessor: 'phone',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.phone')
        },
        {
          accessor: 'name',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.name')
        },
        {
          accessor: 'status',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.status')
        },
        {
          accessor: 'customerConnectedAt',
          header: this.$t(
            'auto-dialer.campaign-cdrs.table.columns.customerConnectedAt'
          )
        },
        {
          accessor: 'waitingTime',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.waitingTime')
        },
        {
          accessor: 'talkTime',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.talkTime')
        },
        {
          accessor: 'duration',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.duration')
        },
        {
          accessor: 'agentConnectedAt',
          header: this.$t(
            'auto-dialer.campaign-cdrs.table.columns.agentConnectedAt'
          )
        },
        {
          accessor: 'agent',
          header: this.$t('auto-dialer.campaign-cdrs.table.columns.agent')
        },
        {
          accessor: 'recordingLink',
          header: this.$t(
            'auto-dialer.campaign-cdrs.table.columns.recordingLink'
          )
        }
      ]
    },
    statusOptions() {
      return [
        {
          id: 'User did not answer',
          name: this.$t(
            'auto-dialer.campaign-cdrs.statusOptions.User did not answer'
          )
        },
        {
          id: 'Airplane Mode Enabled',
          name: this.$t(
            'auto-dialer.campaign-cdrs.statusOptions.Airplane Mode Enabled'
          )
        },
        {
          id: 'Call Failed',
          name: this.$t('auto-dialer.campaign-cdrs.statusOptions.Call Failed')
        },
        {
          id: 'User Busy/Rejected By User',
          name: this.$t(
            'auto-dialer.campaign-cdrs.statusOptions.User Busy/Rejected By User'
          )
        },
        {
          id: 'User Unreachable (Out of Network Coverage or Airplane Mode)',
          name: this.$t(
            'auto-dialer.campaign-cdrs.statusOptions.User Unreachable (Out of Network Coverage or Airplane Mode)'
          )
        },
        {
          id: 'Completed',
          name: this.$t('auto-dialer.campaign-cdrs.statusOptions.Completed')
        },
        {
          id: 'Timeout',
          name: this.$t('auto-dialer.campaign-cdrs.statusOptions.Timeout')
        },
        {
          id: 'Abandoned',
          name: this.$t('auto-dialer.campaign-cdrs.statusOptions.Abandoned')
        }
      ]
    }
  },
  methods: {
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    async fetchCampaignCdrs(page = 1) {
      try {
        this.isLoading = true
        const cdrs = await AutoDialerService.getCampaignCDRs(
          this.campaignId,
          page,
          10,
          {
            ...this.filters,
            statuses: this.filters.statuses.map(status => status.id),
            agents: this.filters.agents.map(agent => agent.ext)
          }
        )
        const { callRequests, ...pagination } = cdrs.data
        this.cdrs = callRequests
        this.pagination = { ...this.pagination, ...pagination }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async fetchExtensions() {
      try {
        const res = await ExtensionService.getAllExtensionsNumbers()
        this.extensions = res.map(ext => ({
          ...ext,
          name: `${ext.name} (${ext.ext})`,
          id: ext.ext
        }))
      } catch (error) {
        this.toast(error.message, {
          type: 'error'
        })
      }
    },
    async fetchCampaignDetails() {
      try {
        const res = await AutoDialerService.getCampaign(this.campaignId)
        this.assignedAgents = this.extensions.filter(ext =>
          res.data.campaign.assignedAgents.includes(+ext.ext)
        )
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    clearFilters() {
      this.filters = {
        statuses: [],
        phone: '',
        agents: []
      }
      this.fetchCampaignCdrs()
    },
    OnFilterUpdate() {
      this.pagination.page = 1
      this.$router.push({ query: { page: 1 } })
      this.fetchCampaignCdrs()
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCampaignCdrs(page)
      this.$router.push({ query: { page } })
    }
  },
  filters: {
    formatDate(date) {
      if (!date) return ''
      return moment(date).format('YYYY/MM/DD HH:mm:ss a')
    }
  },
  async mounted() {
    this.pagination.page = this.$route.query.page || 1

    this.fetchCampaignCdrs()
    await this.fetchExtensions()
    this.fetchCampaignDetails()
  }
}
</script>

<style scoped lang="scss">
#campaign-cdrs {
  .head {
    background-color: #fdfdfa;
    padding: 1rem;
    border-radius: 0.25rem;
  }
  .filters {
    gap: 1rem;
  }
  .filters-actions {
    gap: 1rem;
  }
}

.select {
  max-width: 400px;
}
</style>
