<template>
  <div class="page" id="update-auto-dialer">
    <div class="p-4 card">
      <h2 class="mt-0">
        {{ $t('auto-dialer.update.title', { campaignName: model.name }) }}
      </h2>

      <form class="form-container">
        <div class="row">
          <div class="col-12">
            <h4 class="mt-0 mb-2">
              {{ $t('auto-dialer.update.form.basic') }}
            </h4>
          </div>
          <div class="mb-2 col-12">
            <Field
              :label="$t('auto-dialer.update.form.name.label')"
              :placeholder="$t('auto-dialer.update.form.name.placeholder')"
              :info="$t('auto-dialer.update.form.name.info')"
              v-model="model.name"
              :error="errors[`name`]"
            />
          </div>
        </div>

        <hr class="w-100" />
        <div class="callers-container row">
          <div class="col-12">
            <h4 class="mt-0 mb-2">
              {{ $t('auto-dialer.update.form.callers.title') }}
            </h4>
          </div>

          <div
            v-for="(caller, idx) in model.callers"
            :key="caller.id"
            class="align-items-start col-12 row"
          >
            <div class="col-11 row">
              <div class="col-12 col-md-6">
                <Field
                  :label="$t('auto-dialer.update.form.destinations.label')"
                  :error="errors[`callers[${idx}].destination`]"
                  :info="$t('auto-dialer.update.form.destinations.info')"
                >
                  <b-form-select
                    class="border"
                    @change="onCountryChange($event, idx, caller.id)"
                    v-model="model.callers[idx].destination"
                  >
                    <b-form-select-option
                      v-for="country in countries"
                      :key="country.code"
                      :value="country.code"
                      :disabled="
                        model.callers[idx].destination !== country.code &&
                          selectedCountries.includes(country.code)
                      "
                    >
                      {{ country.emoji }} {{ country.name }}
                    </b-form-select-option>
                  </b-form-select>
                </Field>
              </div>

              <div class="col-12 col-md-6">
                <Field
                  :label="$t('auto-dialer.update.form.caller-ids.label')"
                  :error="errors[`callers[${idx}].callerNumber`]"
                  :info="$t('auto-dialer.update.form.caller-ids.info')"
                >
                  <b-form-select
                    class="border"
                    v-model="model.callers[idx].callerNumber"
                    :options="callerNumbers"
                  />
                </Field>
              </div>
            </div>

            <div class="mt-4 col-1">
              <b-button
                class="px-3 py-2"
                variant="danger"
                @click="handleRemoveCaller(idx)"
              >
                <i class="fa fa-trash"></i>
              </b-button>
            </div>
          </div>

          <p class="mx-3 text-danger">
            {{ errors[`callers`] }}
          </p>

          <div class="col-12">
            <b-button class="px-3 py-2" @click="handleAddCaller">
              {{ $t('auto-dialer.update.form.add-caller') }}
            </b-button>
          </div>
        </div>

        <hr class="w-100" />
        <div class="agents row">
          <div class="col-12">
            <h4 class="mt-0 mb-2">
              {{ $t('auto-dialer.create.step1.form.agents.title') }}
            </h4>
          </div>
          <div class="col-12">
            <Field
              :label="$t('auto-dialer.create.step1.form.agents.agent.label')"
              :error="errors[`agents`]"
              :info="$t('auto-dialer.create.step1.form.agents.agent.info')"
            >
              <Select
                searchable
                multiple
                :placeholder="
                  $t('auto-dialer.create.step1.form.agents.agent.placeholder')
                "
                v-model="model.agents"
                :options="extensions"
                :preselectFirst="false"
              />
            </Field>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-4 stepper-pagination">
          <button
            class="px-3 py-2 btn btn-primary"
            @click="submit"
            type="button"
            :disabled="loading"
          >
            {{
              loading
                ? $t('auto-dialer.update.form.loading')
                : $t('auto-dialer.update.form.continue')
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import { onlyAcceptNumbers } from '../../../utils/utils'

import momentTimezone from 'moment-timezone'
import vocabService from '../../../services/vocab.service'
import AutoDialerService from '../../../services/autoDialer.service'
import { array, object, string } from 'yup'
import { yupToKV } from '../../../utils/yup'
import ExtensionService from '../../../services/extension.service'

export default {
  components: {
    Field
  },
  computed: {
    schema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('auto-dialer.update.form.name.required')),
        callers: array()
          .of(
            object().shape({
              destination: string()
                .trim()
                .required(
                  this.$t('auto-dialer.update.form.destinations.required')
                ),
              callerNumber: string()
                .trim()
                .required(
                  this.$t('auto-dialer.update.form.caller-ids.required')
                )
            })
          )
          .min(1, this.$t('auto-dialer.update.form.callers.min')),
        agents: array()
          .of(
            string().required(
              this.$t('auto-dialer.create.step1.form.agent.required')
            )
          )
          .min(1, this.$t('auto-dialer.create.step1.form.agents.agent.min'))
      })
    }
  },
  data() {
    return {
      loading: false,
      model: {
        name: '',
        callers: [{ destination: '', callerNumber: '' }],
        agents: []
      },
      errors: {},
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      extensions: []
    }
  },
  methods: {
    // utils
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    // handlers
    handleAddCaller() {
      this.model.callers.push({ destination: '', callerNumber: '' })
    },
    handleRemoveCaller(index) {
      const caller = this.model.callers[index]
      this.selectedCountries = this.selectedCountries.filter(
        country => country !== caller.destination
      )

      this.model.callers.splice(index, 1)
    },
    onCountryChange(value, idx) {
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
    },
    // api calls
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    },
    async submit() {
      try {
        this.loading = true
        this.errors = {}

        const data = {
          ...this.model,
          agents: this.model.agents.map(ext => +ext.ext)
        }

        await this.schema.validate(data, { abortEarly: false }).catch(err => {
          this.errors = yupToKV(err)
        })

        if (!this.schema.isValidSync(data)) {
          return
        }

        const campaignId = this.$route.params.id
        await AutoDialerService.updateCampaignMainInfo(campaignId, data)

        this.$router.push({
          name: 'auto-dialer'
        })
        this.toast(this.$t('auto-dialer.update.toasts.campaign-updated'))
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async fetchCampaign() {
      try {
        const res = await AutoDialerService.getCampaign(this.$route.params.id)
        this.model = {
          agents: this.extensions.filter(ext =>
            res.data.campaign.assignedAgents.includes(+ext.ext)
          ),
          name: res.data.campaign.name,
          callers: res.data.campaign.callers.map(caller => ({
            destination: caller.destination,
            callerNumber: caller.callerNumber
          }))
        }

        this.selectedCountries = this.model.callers.map(
          caller => caller.destination
        )
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    async getExtensions() {
      try {
        const res = await ExtensionService.getAllExtensions()
        this.extensions = res.map(ext => ({
          ...ext,
          name: `${ext.name} (${ext.ext})`,
          id: ext.ext
        }))
      } catch (error) {
        this.toast(error.message, {
          type: 'error'
        })
      }
    }
  },
  async mounted() {
    let timezone = momentTimezone.tz.guess()
    const regex = new RegExp('^[a-zA-Z_]+\\/[a-zA-Z_]+$')
    if (!regex.test(timezone)) {
      timezone = 'Asia/Riyadh'
    }

    this.model.timezone = timezone

    this.getCountries()
    this.getCallerNumbers()
    await this.getExtensions()
    this.fetchCampaign()
  }
}
</script>

<style scoped lang="scss">
.form-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.callers-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
