import { api } from '../axios'
import { objToFormData } from '../utils/formData'
import { toQueryString } from '../utils/utils'

/**
 * List all call surveys with pagination and filters.
 * @param {number} [page=1] - The page number.
 * @param {number} [limit=10] - The number of items per page.
 * @param {Object} filters - The filters to apply.
 * @returns {Promise<Object>} The response data.
 */
const listAllCallSurveys = async (filters, page = 1, limit = 1) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `surveys?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

/**
 * Create a new survey.
 * @param {Object} data - The survey data.
 * @returns {Promise<Object>} The response data.
 */
const createSurvey = async data => {
  const res = await api.post('surveys', data)
  return res.data
}

/**
 * Download the survey template.
 * @returns {Promise<Object>} The response data.
 */
const downloadTemplate = async () => {
  const res = await api.get('surveys/template')
  return res.data
}

/**
 * Get a single survey by ID.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const getOneSurvey = async id => {
  const res = await api.get(`surveys/${id}`)
  return res.data
}

/**
 * Upload a survey file.
 * @param {number} id - The survey ID.
 * @param {Object} data - The file data.
 * @returns {Promise<Object>} The response data.
 */
const uploadCustomersFile = async (id, file, isDraft) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('isDraft', isDraft)

  const res = await api.post(`surveys/${id}/file`, formData)
  return res.data
}

/**
 * Start a survey.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const startSurvey = async id => {
  const res = await api.patch(`surveys/${id}/start`)
  return res.data
}

/**
 * Pause a survey.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const pauseSurvey = async id => {
  const res = await api.patch(`surveys/${id}/pause`)
  return res.data
}

/**
 * Resume a paused survey.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const resumeSurvey = async id => {
  const res = await api.patch(`surveys/${id}/resume`)
  return res.data
}

/**
 * Finish a survey.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const finishSurvey = async id => {
  const res = await api.patch(`surveys/${id}/finish`)
  return res.data
}

/**
 * Cancel a survey.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const cancelSurvey = async id => {
  const res = await api.patch(`surveys/${id}/cancel`)
  return res.data
}

/**
 * Ignore all corrupted rows in a survey.
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 *
 * @param {number} id - The survey ID.
 * @returns {Promise<Object>} The response data.
 */
const ignoreAllCorrupted = async id => {
  const res = await api.patch(`surveys/${id}/ignore-corrupted`)
  return res.data
}

/**
 * Fix a corrupted row in a survey.
 * @param {number} id - The survey ID.
 * @param {number} rowId - The corrupted row ID.
 * @param {Object} data - The data to fix the row.
 * @returns {Promise<Object>} The response data.
 */
const fixCorruptedRow = async (id, rowId, data) => {
  const res = await api.put(`surveys/${id}/corrupted-rows/${rowId}/fix`, data)
  return res.data
}

/**
 * Get corrupted rows in a survey.
 * @param {number} id - The survey ID.
 * @param {number} [page=1] - The page number.
 * @param {number} [limit=10] - The number of items per page.
 * @returns {Promise<Object>} The response data.
 */
const getCorruptedRows = async (id, page = 1, limit = 10) => {
  const res = await api.get(
    `surveys/${id}/corrupted-rows?page=${page}&limit=${limit}`
  )
  return res.data
}

/**
 * Uploads a survey sound file to the server.
 *
 * @param {File} file - The sound file to be uploaded.
 * @returns {Promise<Object>} The response data from the server.
 */
const uploadSurveySound = async file => {
  if (!file) return
  const formData = objToFormData({ file })
  const res = await api.post(`survey-sounds/upload`, formData)
  return res.data
}

/**
 * Fetches the CDRs of a survey.
 *
 * @param {number} id - The survey ID.
 * @param {number} [page=1] - The page number.
 * @param {number} [limit=10] - The number of items per page.
 * @returns {Promise<Object>} The response data from the server.
 */
const fetchSurveyCdrs = async (id, filters, page = 1, limit = 10) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `surveys/${id}/cdrs?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

/**
 * Get Survey metrics.
 *
 * @param {number} surveyId - The campaign ID.
 * @returns {Promise<Object>} The response data from the server.
 */
const getSurveyMetrics = async surveyId => {
  const res = await api.get(`surveys/${surveyId}/current-calls/stats`)
  return res.data
}

/**
 * Get Survey UnCompleted Calls.
 *
 * @param {number} surveyId - The survey ID.
 * @param {number} [page=1] - The page number.
 * @param {number} [limit=10] - The number of items per page.
 * @returns {Promise<Object>} The response data from the server.
 */
const getSurveyUnCompletedRequests = async (surveyId, page = 1, limit = 1) => {
  const res = await api.get(
    `surveys/${surveyId}/uncompleted-requests?page=${page}&limit=${limit}`
  )
  return res.data
}

/**
 * Downloads the uncompleted requests for a given survey.
 *
 * @param {string} surveyId - The ID of the survey.
 * @returns {Promise<Object>} A promise that resolves to the data of the uncompleted requests.
 */
const downloadUnCompletedRequests = async surveyId => {
  const res = await api.get(`surveys/${surveyId}/uncompleted-requests/export`)
  return res.data
}

/**
 * Fetches the analytics data for a specific survey.
 *
 * @param {string} surveyId - The ID of the survey to fetch analytics for.
 * @returns {Promise<Object>} A promise that resolves to the survey analytics data.
 */
const fetchSurveyAnalytics = async surveyId => {
  const res = await api.get(`surveys/${surveyId}/analysis`)
  return res.data
}

/**
 * Downloads the report for a survey.
 *
 * @param {string} surveyId - The ID of
 * @returns {Promise<Object>} A promise that resolves to the data of the exported surveys.
 */
const downloadReport = async surveyId => {
  const res = await api.get(`surveys/${surveyId}/stats/export`)
  return res.data
}

const CallSurveyService = {
  listAllCallSurveys,
  createSurvey,
  downloadTemplate,
  getOneSurvey,
  uploadCustomersFile,
  startSurvey,
  pauseSurvey,
  resumeSurvey,
  finishSurvey,
  cancelSurvey,
  ignoreAllCorrupted,
  fixCorruptedRow,
  getCorruptedRows,
  uploadSurveySound,
  fetchSurveyCdrs,
  getSurveyMetrics,
  getSurveyUnCompletedRequests,
  downloadUnCompletedRequests,
  fetchSurveyAnalytics,
  downloadReport
}

export default CallSurveyService
