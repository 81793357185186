export const downloadCSV = (data, filename) => {
  if (data.length === 0) return

  const header = Object.keys(data[0]).join(',')
  const csv = [header, ...data.map(row => Object.values(row).join(','))].join(
    '\n'
  )
  const csvFile = new Blob([csv], { type: 'text/csv' })
  const downloadLink = document.createElement('a')
  downloadLink.download = filename
  downloadLink.href = URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
