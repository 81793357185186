import { api } from '../axios'
import { objToFormData } from '../utils/formData'
import { toQueryString } from '../utils/utils'

const listCampaigns = async (filters = {}, page = 1, limit = 10) => {
  const filtersString = toQueryString(filters)

  const res = await api.get(
    `auto-dialer/campaigns?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

const getCampaign = async campaignId => {
  const res = await api.get(`auto-dialer/campaigns/${campaignId}`)
  return res.data
}

const createCampaign = async data => {
  const formData = objToFormData(data)
  console.log(formData)

  const res = await api.post('auto-dialer/campaigns', formData)
  return res.data
}

const attachCustomersToCampaign = async (campaignId, data) => {
  const res = await api.post(
    `auto-dialer/campaigns/${campaignId}/customers`,
    data
  )
  return res.data
}

const uploadCustomersFile = async (campaignId, file, isDraft) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('isDraft', isDraft)

  const res = await api.post(
    `auto-dialer/campaigns/${campaignId}/file`,
    formData
  )
  return res.data
}

const assignAgentsToCampaign = async (campaignId, data) => {
  const res = await api.patch(
    `auto-dialer/campaigns/${campaignId}/agents`,
    data
  )
  return res.data
}

const deleteCampaign = async campaignId => {
  const res = await api.delete(`auto-dialer/campaigns/${campaignId}`)
  return res.data
}

const startCampaign = async campaignId => {
  const res = await api.patch(`auto-dialer/campaigns/${campaignId}/start`)
  return res.data
}

const resumeCampaign = async campaignId => {
  const res = await api.patch(`auto-dialer/campaigns/${campaignId}/resume`)
  return res.data
}

const pauseCampaign = async campaignId => {
  const res = await api.patch(`auto-dialer/campaigns/${campaignId}/pause`)
  return res.data
}

const finishCampaign = async campaignId => {
  const res = await api.patch(`auto-dialer/campaigns/${campaignId}/finish`)
  return res.data
}
const updateCampaign = async (campaignId, data) => {
  const formData = objToFormData(data)
  const res = await api.put(`auto-dialer/campaigns/${campaignId}`, formData)
  return res.data
}
const updateCampaignMainInfo = async (campaignId, data) => {
  const res = await api.patch(`auto-dialer/campaigns/${campaignId}`, data)
  return res.data
}

const downloadReport = async campaignId => {
  const res = await api.get(`auto-dialer/campaigns/${campaignId}/stats`)
  return res.data
}

const removeCustomerFromCampaign = async (campaignId, customerId) => {
  const res = await api.delete(
    `auto-dialer/campaigns/${campaignId}/customers/${customerId}`
  )
  return res.data
}

const downloadTemplate = async () => {
  const res = await api.get('auto-dialer/campaigns/template')
  return res.data
}

const fetchCorruptedRows = async (campaignId, page = 1, limit = 10) => {
  const res = await api.get(
    `auto-dialer/campaigns/${campaignId}/corrupted-rows?page=${page}&limit=${limit}`
  )
  return res.data
}

const fixCorruptedRow = async (campaignId, rowId, data) => {
  const res = await api.put(
    `auto-dialer/campaigns/${campaignId}/corrupted-rows/${rowId}/fix`,
    data
  )
  return res.data
}

const ignoreAllCorruptedRows = async campaignId => {
  const res = await api.patch(
    `auto-dialer/campaigns/${campaignId}/ignore-corrupted`
  )
  return res.data
}

const stopProcessing = async campaignId => {
  const res = await api.patch(`auto-dialer/campaigns/${campaignId}/cancel`)
  return res.data
}

const getCampaignMetrics = async campaignId => {
  const res = await api.get(`auto-dialer/campaigns/${campaignId}/metrics`)
  return res.data
}

const getInitiatedCalls = async campaignId => {
  const res = await api.get(
    `auto-dialer/campaigns/${campaignId}/current-initiated-calls`
  )
  return res.data
}

const getCampaignCDRs = async (campaignId, page, limit = 10, filters = {}) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `auto-dialer/campaigns/${campaignId}/cdrs?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

const getCampaignUnCompletedRequests = async (
  campaignId,
  page = 1,
  limit = 1
) => {
  const res = await api.get(
    `auto-dialer/campaigns/${campaignId}/uncompleted-requests?page=${page}&limit=${limit}`
  )
  return res.data
}

const downloadUnCompletedRequests = async campaignId => {
  const res = await api.get(
    `auto-dialer/campaigns/${campaignId}/uncompleted-requests/export`
  )
  return res.data
}

// Agent
const listAgentCampaigns = async (filters = {}, page = 1, limit = 10) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `auto-dialer-agent/campaigns?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

const getAgentCampaignCDRs = async (
  campaignId,
  page,
  limit = 10,
  filters = {}
) => {
  const filtersString = toQueryString(filters)
  const res = await api.get(
    `auto-dialer-agent/campaigns/${campaignId}/cdrs?page=${page}&limit=${limit}&${filtersString}`
  )
  return res.data
}

const joinCampaign = async campaignId => {
  const res = await api.patch(`auto-dialer-agent/campaigns/${campaignId}/join`)
  return res.data
}

const leaveCampaign = async campaignId => {
  const res = await api.patch(`auto-dialer-agent/campaigns/${campaignId}/leave`)
  return res.data
}

const AutoDialerService = {
  listCampaigns,
  createCampaign,
  getCampaign,
  attachCustomersToCampaign,
  assignAgentsToCampaign,
  deleteCampaign,
  startCampaign,
  resumeCampaign,
  pauseCampaign,
  finishCampaign,
  updateCampaign,
  updateCampaignMainInfo,
  downloadReport,
  removeCustomerFromCampaign,
  downloadTemplate,
  uploadCustomersFile,
  fetchCorruptedRows,
  fixCorruptedRow,
  ignoreAllCorruptedRows,
  stopProcessing,
  getCampaignMetrics,
  getCampaignCDRs,
  listAgentCampaigns,
  getAgentCampaignCDRs,
  joinCampaign,
  leaveCampaign,
  getCampaignUnCompletedRequests,
  downloadUnCompletedRequests,
  getInitiatedCalls
}

export default AutoDialerService
