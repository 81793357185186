<template>
  <div class="d-flex flex-column flex-fill incoming-call-pad">
    <p>incoming call</p>
    <h5>{{ number }}</h5>
    <h2>{{ name }}</h2>

    <div class="">
      <div class="mt-auto actions col-12">
        <div class="row">
          <div class="col">
            <button class="btn-block p-4 btn btn-danger" @click="onHangup()">
              <img src="../../assets/icons/hangup.svg" alt="" />
            </button>
          </div>
          <div class="col">
            <button class="btn-block p-4 btn btn-primary" @click="onAnswer()">
              <img src="../../assets/icons/phone.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { parseUser } from '@/utils/utils'

export default {
  props: {
    call: {
      type: Object,
      required: true
    }
  },
  computed: {
    number() {
      return this.call.request.from.uri.user
    },
    name() {
      const { name } = parseUser(
        this.call.request.from.display_name || this.number
      )
      return name
    }
  },
  methods: {
    onHangup() {
      this.$emit('hangup')
    },
    onAnswer() {
      this.$emit('answer')
    }
  }
}
</script>

<style lang="scss" scoped></style>
