<template>
  <div :dir="dir">
    <div class="mb-4 row">
      <div class="col-12 col-lg-12">
        <PieChart
          :config="callsChart.config"
          :labels="callsChart.labels"
          :datasets="callsChart.datasets"
        />
        <div class="mt-4 agents-metrics row">
          <div class="p-2 col-3">
            <div class="metric-card">
              <h6>
                {{ $t('call-survey.survey-metrics.summary.chart.totalCalls') }}
              </h6>
              <p>
                {{
                  liveMetrics.callsStats.inProgress +
                    liveMetrics.callsStats.initiated
                }}
              </p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="metric-card primary">
              <h6>
                {{
                  $t('call-survey.survey-metrics.summary.chart.inProgressCalls')
                }}
              </h6>
              <p>{{ liveMetrics.callsStats.inProgress }}</p>
            </div>
          </div>
          <div class="p-2 col-3">
            <div class="metric-card orange">
              <h6>
                {{
                  $t('call-survey.survey-metrics.summary.chart.initiatedCalls')
                }}
              </h6>
              <p>{{ liveMetrics.callsStats.initiated }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5 class="mt-0">
      {{ $t('call-survey.survey-metrics.tables.initiated-calls.title') }}
    </h5>
    <data-table
      class="table"
      ref="initiatedCallsTable"
      :data="liveMetrics.initiatedCalls"
      :fields="translatedInitiatedCallsTableFields"
      :isLoading="loading"
      :pagination="pagination"
    >
    </data-table>

    <h5 class="mt-0">
      {{ $t('call-survey.survey-metrics.tables.in-progress-calls.title') }}
    </h5>
    <data-table
      class="table"
      ref="inProgressCallsTable"
      :data="liveMetrics.inProgressCalls"
      :fields="translatedInProgressCallsTableFields"
      :isLoading="loading"
      :pagination="pagination"
    >
    </data-table>
  </div>
</template>

<script>
import PieChart from '../../../elements/charts/PieChart.vue'
import DataTable from '../../../elements/DataTable.vue'
import { mapGetters } from 'vuex'
import CallSurveyService from '../../../services/call-survey.service'
import moment from 'moment-timezone'

export default {
  name: 'survey-metrics',
  components: {
    PieChart,
    DataTable
  },
  props: {
    metrics: Object,
    surveyId: String
  },
  data() {
    return {
      callsChart: {
        labels: [
          this.$t('call-survey.survey-metrics.summary.chart.inProgressCalls'),
          this.$t('call-survey.survey-metrics.summary.chart.initiatedCalls')
        ],
        datasets: [
          this.metrics.callsStats.inProgress,
          this.metrics.callsStats.initiated
        ]
      },
      liveMetrics: null,
      pagination: {
        perPage: 10
      },
      loading: false,
      interval: null,
      perPageOptions: [
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 100
        }
      ]
    }
  },
  filters: {
    formatTime(time) {
      return time ? moment(time).format('YYYY-MM-DD HH:mm a') : ''
    }
  },
  computed: {
    ...mapGetters(['extensionStatus']),
    currentExtension() {
      return this.$root.$refs.phone
        ? this.$root.$refs.phone.extension.ext
        : null
    },
    translatedInitiatedCallsTableFields() {
      return [
        {
          name: 'name',
          title: this.$t(
            'call-survey.survey-metrics.tables.initiated-calls.columns.name'
          )
        },
        {
          name: 'phone',
          title: this.$t(
            'call-survey.survey-metrics.tables.initiated-calls.columns.phone'
          )
        },
        {
          name: 'currentTrial',
          title: this.$t(
            'call-survey.survey-metrics.tables.initiated-calls.columns.trial'
          )
        }
      ]
    },
    translatedInProgressCallsTableFields() {
      return [
        {
          name: 'name',
          title: this.$t(
            'call-survey.survey-metrics.tables.in-progress-calls.columns.name'
          )
        },
        {
          name: 'phone',
          title: this.$t(
            'call-survey.survey-metrics.tables.in-progress-calls.columns.phone'
          )
        },
        {
          name: 'currentTrial',
          title: this.$t(
            'call-survey.survey-metrics.tables.in-progress-calls.columns.trial'
          )
        }
      ]
    },
    dir() {
      const locale = this.$i18n.locale()
      if (locale === 'ar') return 'rtl'
      else return 'ltr'
    }
  },
  methods: {
    onPerPageChange(perPage) {
      this.pagination.perPage = perPage
      this.$refs.agentDetailsTable.onPaginationData(this.pagination)
    }
  },
  mounted() {
    this.liveMetrics = this.metrics

    this.interval = setInterval(async () => {
      try {
        const metrics = await CallSurveyService.getSurveyMetrics(this.surveyId)
        this.liveMetrics = metrics.data
        this.callsChart.datasets = [
          metrics.data.callsStats.inProgress,
          metrics.data.callsStats.initiated
        ]
      } catch (error) {
        const message = error.response
          ? error.response.data.message
          : error.message
        this.toast(message, {
          type: 'error'
        })
      }
    }, 10000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
.calls-metrics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.metric-card {
  padding: 10px;
  border-radius: 5px;
  border-width: 2px 2px 2px 8px;
  border-style: solid;
  border-color: hsl(199, 0%, 41%);
  background-color: hsla(199, 0%, 41%, 0.1);

  h6,
  p {
    margin: 0;
  }
  &.primary {
    border-color: hsl(199, 100%, 41%);
    background-color: hsla(199, 100%, 41%, 0.1);
  }
  &.success {
    border-color: hsl(153, 68%, 42%);
    background-color: hsla(153, 68%, 42%, 0.1);
  }
  &.info {
    border-color: hsl(207, 82%, 28%);
    background-color: hsla(207, 82%, 28%, 0.1);
  }
  &.danger {
    border-color: hsl(348, 97%, 63%);
    background-color: hsla(348, 97%, 63%, 0.1);
  }
  &.orange {
    border-color: hsl(30, 100%, 48%);
    background-color: hsla(30, 100%, 48%, 0.1);
  }
  &.warning {
    border-color: hsl(44, 100%, 67%);
    background-color: hsla(44, 100%, 67%, 0.1);
  }
}
.table {
  min-height: auto;
}
</style>
