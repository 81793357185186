<template>
  <div
    class="d-flex flex-wrap justify-content-between align-items-center my-2 p-3 rounded file-row"
  >
    <p class="m-0">{{ file.name }}</p>
    <button
      class="px-3 py-2 btn btn-danger"
      @click="removeFile(file)"
      type="button"
      v-if="!readonly"
    >
      <i class="fa fa-trash"></i>
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    file: {
      type: File,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeFile(file) {
      this.$emit('removeFile', file)
    }
  }
}
</script>
<style lang="scss" scoped>
.file-row {
  background-color: #eee;
  gap: 0.5rem;
}
</style>
