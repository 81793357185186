<template>
  <div class="bg-white p-2 dialpad">
    <div class="d-flex align-items-center mb-3 display">
      <input
        ref="inputScreen"
        autofocus
        class="flex-fill border-0 p-3 w-100"
        type="text"
        :placeholder="$t('webrtc.enter-number')"
        v-model="number"
        @input="onInput"
        @keypress="onKeypress"
      />
      <button
        class="ml-2 p-3 digit"
        @click="onBackspaceClick()"
        v-if="!!number"
      >
        <img src="../../assets/icons/backspace.svg" alt="backspace" />
      </button>
    </div>
    <Keypad @digitPress="onDigitPress"></Keypad>
    <div class="no-gutters row">
      <div class="p-1 col-4">
        <b-dropdown
          no-caret
          class="shadow-none h-100"
          toggle-class="digit w-100 h-100 py-2"
          menu-class="shadow"
        >
          <template #button-content>
            <img src="../../assets/icons/menu.svg" alt="logout" />
          </template>
          <b-dropdown-item
            @click="onShowCrmContacts"
            :disabled="!currentTenant.defaultCRM"
          >
            <span class="">{{ $t('webrtc.crm-contacts.title') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onShowCallLog">
            <span class="">{{ $t('webrtc.callLog') }}</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onExtensionLogout">
            <span class="text-danger">{{ $t('webrtc.logout') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="my-auto p-1 col-4">
        <button class="p-3 rounded-circle btn btn-primary" @click="startCall()">
          <img src="../../assets/icons/phone.svg" alt="call" />
        </button>
      </div>
      <div class="p-1 col-4">
        <button class="py-2 w-100 h-100 digit" @click="onShowContacts()">
          <i class="m-0 fa fa-address-book h3" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { digits } from '../../constants/dialpad'
import Keypad from './Keypad.vue'

export default {
  components: { Keypad },
  props: {},
  data() {
    return {
      number: '',
      digits
    }
  },
  methods: {
    onDigitPress(digit) {
      this.number = this.number + digit.value
      setTimeout(() => {
        this.$refs.inputScreen.scrollLeft = this.$refs.inputScreen.scrollWidth
      }, 0)
    },
    onBackspaceClick() {
      this.number = this.number.slice(0, -1)
      setTimeout(() => {
        this.$refs.inputScreen.scrollLeft = this.$refs.inputScreen.scrollWidth
      }, 0)
    },
    onInput(event) {
      const value = event.target.value
      const regex = /^[0-9#*+]*$/

      if (!regex.test(value)) {
        this.number = value.replace(/[^0-9#*+]/g, '')
      }
    },
    onExtensionLogout() {
      this.$emit('extensionLogout')
    },
    startCall() {
      if (!this.number) return
      this.$emit('startCall', this.number)
    },
    onShowContacts() {
      this.$emit('showContacts')
    },
    onShowCrmContacts() {
      this.$emit('showCrmContacts')
    },
    onShowCallLog() {
      this.$emit('showCallLog')
    },
    onKeypress(event) {
      if (event.key === 'Enter') {
        this.startCall()
      }

      const digit = digits.find(digit => digit.value === event.key)
      if (!digit) return

      var audio = new Audio(digit.tone)
      audio.play()
    }
  },
  computed: {
    ...mapGetters(['currentTenant'])
  }
}
</script>

<style lang="scss" scoped>
.dialpad {
  .display input {
    font-size: 20px;
    outline: none;
    color: #008ecf;
    background: #ededed;
    border-radius: 12px;
  }

  button.digit {
    border: none;
    background: #fff;
    border-radius: 12px;
    outline: none;

    &:hover {
      background: #ededed;
    }
  }
  p,
  h2 {
    margin: 0;
  }

  p small:empty::before {
    content: '';
    display: inline-block;
  }
}
</style>
