<template>
  <div class="page" id="web-call-credentials-edit">
    <div class="flex-row mb-2 p-4 card preview">
      <div
        class="icon"
        :style="{ 'background-color': model.iconBackgroundColor }"
      >
        <img src="../../../../assets/images/web-call-icon.svg" alt="icon" />
      </div>
      <div
        class="text-container"
        :style="{ 'background-color': model.iconBaseColor }"
      >
        <div class="text">
          <span class="icon-text" :style="{ color: model.iconFontColor }">
            {{ model.iconText }}
          </span>
        </div>
      </div>
    </div>

    <div class="form">
      <div class="mb-2 p-4 card">
        <h2 class="mt-0 mb-1">
          {{ $t('developer-tab.web-call-credentials.form.style') }}
        </h2>
        <div class="row">
          <div class="col-12">
            <Field
              :label="$t('developer-tab.web-call-credentials.form.iconText')"
              :placeholder="
                $t(
                  'developer-tab.web-call-credentials.form.iconTextPlaceholder'
                )
              "
              :error="errors.iconText"
              v-model="model.iconText"
            ></Field>
          </div>

          <div class="col-12 col-lg-4">
            <Field
              :label="
                $t('developer-tab.web-call-credentials.form.backgroundColor')
              "
              :error="errors.iconBackgroundColor"
            >
              <b-form-input type="color" v-model="model.iconBackgroundColor" />
            </Field>
          </div>

          <div class="col-12 col-lg-4">
            <Field
              :label="
                $t('developer-tab.web-call-credentials.form.iconBaseColor')
              "
              :error="errors.iconBaseColor"
            >
              <b-form-input type="color" v-model="model.iconBaseColor" />
            </Field>
          </div>
          <div class="col-12 col-lg-4">
            <Field
              :label="$t('developer-tab.web-call-credentials.form.fontColor')"
              :error="errors.iconFontColor"
            >
              <b-form-input type="color" v-model="model.iconFontColor" />
            </Field>
          </div>
        </div>
      </div>

      <div class="mb-2 p-4 card">
        <h2 class="mt-0 mb-1">
          {{ $t('developer-tab.web-call-credentials.form.configuration') }}
        </h2>
        <div class="row">
          <div class="col-12 col-lg-6">
            <Field
              :label="$t('developer-tab.web-call-credentials.form.callerId')"
              :error="errors.callerId"
            >
              <b-form-select
                class="border"
                v-model="model.callerId"
                :options="callerIds"
              >
                <template v-slot:first>
                  <b-form-select-option disabled value="">
                    {{
                      $t(
                        'developer-tab.web-call-credentials.form.callerIdPlaceholder'
                      )
                    }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </Field>
          </div>
          <div class="col-12 col-lg-6">
            <Field
              :label="
                $t('developer-tab.web-call-credentials.form.destinationNumber')
              "
              :error="errors.destinationNumber"
            >
              <b-form-input
                class="border"
                :placeholder="
                  $t(
                    'developer-tab.web-call-credentials.form.destinationNumberPlaceholder'
                  )
                "
                v-model="model.destinationNumber"
                min="1"
                @keydown="onlyAcceptNumbers"
              />
            </Field>
          </div>
          <div class="col-12 col-lg-6">
            <Field
              :label="
                $t('developer-tab.web-call-credentials.form.concurrentCalls')
              "
              :error="errors.concurrentCalls"
            >
              <b-form-input
                type="number"
                class="border"
                v-model="model.concurrentCalls"
                min="1"
                @keydown="onlyAcceptNumbers"
              />
            </Field>
          </div>
        </div>
      </div>

      <div class="mb-2 p-4 card">
        <h2 class="mt-0 mb-1">
          {{
            $t('developer-tab.web-call-credentials.form.allowedDomainsTitle')
          }}
        </h2>
        <Field
          :label="$t('developer-tab.web-call-credentials.form.allowedDomains')"
          :error="errors['allowedDomains']"
        >
          <div class="domains">
            <div
              class="domain-col"
              v-for="(_, idx) in model.allowedDomains"
              :key="idx"
            >
              <div class="domain">
                <b-form-input
                  class="border"
                  v-model="model.allowedDomains[idx].domain"
                  type="text"
                  placeholder="example.com"
                />
                <b-button
                  variant="danger"
                  class="px-3 py-2"
                  @click="removeDomain(idx)"
                >
                  <i class="fa fa-trash"></i>
                </b-button>
              </div>
              <span class="text-danger">
                {{ errors[`allowedDomains[${idx}].domain`] }}
              </span>
            </div>
            <b-button variant="primary" @click="addDomain" class="px-2 py-3">
              {{ $t('developer-tab.web-call-credentials.form.addDomain') }}
            </b-button>
          </div>
        </Field>
      </div>

      <b-button
        variant="primary"
        @click="editCredential"
        :disabled="loading"
        class="px-2 py-3"
      >
        {{
          loading
            ? 'loading...'
            : $t('developer-tab.web-call-credentials.update')
        }}
      </b-button>
    </div>

    <Modal :isOpen="isCreateModalOpen" @dismiss="onCreateModalDismiss">
      <h4 class="m-0 mb-2">
        {{ $t('developer-tab.web-call-credentials.toasts.create') }}
      </h4>
      <div class="copy-btn" role="button" @click="copy(escapedScriptTag)">
        <span class="api-key" v-html="scriptTag"></span>
        <i class="fa fa-copy"></i>
      </div>
    </Modal>
  </div>
</template>

<script>
import WebCallCredentialsService from '../../../../services/webCallCredentials.service'
import Field from '../../../../elements/Field.vue'
import { isValidUrl, onlyAcceptNumbers } from '../../../../utils/utils'
import Modal from '../../../../elements/Modal.vue'
import vocabService from '../../../../services/vocab.service'
import * as yup from 'yup'
import { yupToKV } from '../../../../utils/yup'

export default {
  components: { Field, Modal },
  data() {
    return {
      loading: false,
      model: {
        callerId: '',
        iconText: 'Call Us',
        iconBackgroundColor: '#cccccc',
        iconBaseColor: '#999999',
        iconFontColor: '#FFFFFF',
        destinationNumber: '',
        concurrentCalls: 1,
        allowedDomains: [
          {
            id: new Date().getTime(),
            domain: ''
          }
        ]
      },
      isCreateModalOpen: false,
      callerIds: [],
      errors: {}
    }
  },
  computed: {
    appId() {
      return this.$route.params.id
    },
    scriptTag() {
      return `&lt;script type="module" src="${process.env.VUE_APP_NEW_API}normal-web-call/bundle/${this.$route.params.id}"&gt;&lt;/script&gt;`
    },
    escapedScriptTag() {
      return this.scriptTag.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
    },
    schema() {
      return yup.object().shape({
        callerId: yup
          .string()
          .required(
            this.$t('developer-tab.web-call-credentials.form.callerIdRequired')
          ),
        iconText: yup
          .string()
          .required(
            this.$t('developer-tab.web-call-credentials.form.iconTextRequired')
          ),
        iconBackgroundColor: yup
          .string()
          .required(
            this.$t(
              'developer-tab.web-call-credentials.form.iconBackgroundColorRequired'
            )
          ),
        iconBaseColor: yup
          .string()
          .required(
            this.$t(
              'developer-tab.web-call-credentials.form.iconBaseColorRequired'
            )
          ),
        iconFontColor: yup
          .string()
          .required(
            this.$t(
              'developer-tab.web-call-credentials.form.iconFontColorRequired'
            )
          ),
        destinationNumber: yup
          .string()
          .matches(
            /^\d+$/,
            this.$t(
              'developer-tab.web-call-credentials.form.destinationNumberInvalid'
            )
          )
          .required(
            this.$t(
              'developer-tab.web-call-credentials.form.destinationNumberRequired'
            )
          ),
        concurrentCalls: yup
          .number()
          .min(
            1,
            this.$t(
              'developer-tab.web-call-credentials.form.concurrentCallsMin',
              { min: 1 }
            )
          )
          .required(
            this.$t(
              'developer-tab.web-call-credentials.form.concurrentCallsRequired'
            )
          ),
        allowedDomains: yup
          .array()
          .of(
            yup.object().shape({
              domain: yup
                .string()
                .test(
                  'is-valid-domain',
                  this.$t(
                    'developer-tab.web-call-credentials.form.domainInvalid'
                  ),
                  isValidUrl
                )
                .required(
                  this.$t(
                    'developer-tab.web-call-credentials.form.allowedDomainsRequired'
                  )
                )
            })
          )
          .min(
            1,
            this.$t(
              'developer-tab.web-call-credentials.form.allowedDomainsMin',
              { min: 1 }
            )
          )
      })
    }
  },
  methods: {
    // utils
    copy(text) {
      try {
        navigator.clipboard.writeText(text)
        this.toast(this.$t('developer-tab.web-call-credentials.toasts.copy'))
      } catch (error) {
        console.error('Failed to copy: ', error)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.copyError'),
          {
            type: 'error'
          }
        )
      }
    },
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    async editCredential() {
      try {
        this.errors = {}
        await this.schema
          .validate(this.model, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err)
            console.log(this.errors, this.model)
          })

        if (!this.schema.isValidSync(this.model)) return

        await WebCallCredentialsService.assignAllowedDomains(this.appId, {
          domains: this.model.allowedDomains.map(({ domain }) => domain)
        })

        await WebCallCredentialsService.editWebCallCredentials(this.appId, {
          ...this.model,
          concurrentCalls: parseInt(this.model.concurrentCalls)
        })

        this.isCreateModalOpen = true
      } catch (error) {
        console.error('Failed to edit web call credentials: ', error)
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    async getCallerIds() {
      const result = await vocabService.getCallerIds()
      const callerIds = result.map(cid => ({
        text: cid,
        value: cid
      }))
      this.callerIds = callerIds
    },
    addDomain() {
      this.model.allowedDomains.push({ id: new Date().getTime(), domain: '' })
    },
    removeDomain(index) {
      this.model.allowedDomains = this.model.allowedDomains.filter(
        (_, idx) => idx !== index
      )
      this.errors[`allowedDomains[${index}].domain`] = undefined
    },
    async fetchWebCallCredentials() {
      try {
        const result = await WebCallCredentialsService.getWebCallCredentials(
          this.appId
        )
        this.model = {
          ...this.model,
          ...result,
          allowedDomains: result.domains.map(domain => ({
            id: new Date().getTime(),
            domain
          }))
        }
      } catch (error) {
        console.error('Failed to fetch web call credentials: ', error)
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    onCreateModalDismiss() {
      this.isCreateModalOpen = false
      this.$router.push({
        name: 'web-call-credentials'
      })
    }
  },
  mounted() {
    this.getCallerIds()
    this.fetchWebCallCredentials()
  }
}
</script>

<style lang="scss" scoped>
.copy-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  gap: 0.5rem;
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #f3f3f3 25%, transparent 25%),
    linear-gradient(-45deg, #f3f3f3 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #f3f3f3 75%),
    linear-gradient(-45deg, transparent 75%, #f3f3f3 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

  .icon {
    width: 75px;
    height: 75px;
    background-color: #999;
    border-radius: 999px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50%;
      height: 50%;
      object-fit: contain;
    }
  }

  .text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ccc;
    border-radius: 999px;
    padding: 0.5rem 3rem 0.5rem 3rem;
    margin-inline-start: -32px;
    .text {
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-text {
        font-size: 1.35rem;
      }
    }
  }
}
.domains {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: start;

  .domain-col {
    width: 100%;
  }

  .domain {
    display: flex;
    gap: 1rem;
    align-items: center;
    flex: 1;

    input {
      flex: 1;
      width: 100%;
    }
  }
}
</style>
