<template>
  <div class="page" id="details-call-survey">
    <div class="p-3 card">
      <h1 class="m-0">
        {{ $t('call-survey.details.title') }} ({{ model.name }})
      </h1>

      <p class="m-0">
        {{ $t('call-survey.details.description') }}
      </p>

      <div class="survey">
        <div class="p-3 card">
          <div class="mb-4 basic">
            <h4 class="mt-0 mb-2">
              {{ $t('call-survey.create.form.titles.basic') }}
            </h4>
            <div class="row">
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="$t('call-survey.create.form.name.label')"
                  :placeholder="$t('call-survey.create.form.name.placeholder')"
                  :info="$t('call-survey.create.form.name.info')"
                  v-model="model.name"
                  :error="errors[`name`]"
                  disabled
                >
                </Field>
              </div>
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="$t('call-survey.create.form.trials-count.label')"
                  :error="errors[`trialsCount`]"
                  :info="$t('call-survey.create.form.trials-count.info')"
                >
                  <b-form-spinbutton
                    class="border"
                    v-model="model.trialsCount"
                    min="1"
                    max="10"
                    wrap
                    placeholder="-"
                    disabled
                  />
                </Field>
              </div>
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="$t('call-survey.create.form.concurrencyCalls.label')"
                  :error="errors[`concurrencyCalls`]"
                  :info="$t('call-survey.create.form.concurrencyCalls.info')"
                >
                  <b-form-spinbutton
                    class="border"
                    v-model="model.concurrencyCalls"
                    min="1"
                    max="10"
                    wrap
                    placeholder="-"
                    disabled
                  />
                </Field>
              </div>
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="
                    $t(
                      'call-survey.create.form.delay-minutes-between-trials.label'
                    )
                  "
                  :error="errors[`delayMinutesBetweenTrials`]"
                  :info="
                    $t(
                      'call-survey.create.form.delay-minutes-between-trials.info'
                    )
                  "
                >
                  <b-form-input
                    class="border"
                    v-model="model.delayMinutesBetweenTrials"
                    type="number"
                    min="5"
                    @keydown="onlyAcceptNumbers"
                    disabled
                  />
                </Field>
              </div>
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="$t('call-survey.create.form.dtmf-timeout.label')"
                  :error="errors[`dtmfTimeout`]"
                  :info="$t('call-survey.create.form.dtmf-timeout.info')"
                >
                  <b-form-input
                    class="border"
                    v-model="model.dtmfTimeout"
                    type="number"
                    min="5"
                    @keydown="onlyAcceptNumbers"
                    disabled
                  />
                </Field>
              </div>
              <div class="mb-2 col-4 py-3">
                <!-- <b-form-checkbox
                  v-model="model.allowDTMFInputDuringPlayback"
                  disabled
                >
                  {{
                    $t(
                      'call-survey.create.form.enable-keypad-input-during-playback.label'
                    )
                  }}
                </b-form-checkbox> -->
              </div>
            </div>
          </div>

          <div class="mb-4 sounds">
            <h4 class="mt-0 mb-2">
              {{ $t('call-survey.create.form.titles.sounds') }}
            </h4>
            <div class="row">
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-survey.create.form.startSound.label')"
                  :info="$t('call-survey.create.form.startSound.info')"
                  :error="errors[`startSound`]"
                >
                  <DropzoneFile
                    readonly
                    v-if="model.startSoundFileName && !model.startSound"
                    :file="{
                      name: model.startSoundFileName
                    }"
                    @removeFile="model.startSoundFileName = null"
                  />

                  <Dropzone
                    v-else
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.startSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-survey.create.form.startSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{ $t('call-survey.create.form.startSound.subtitle') }}
                      </div>
                      <div class="description">
                        {{
                          $t('call-survey.create.form.startSound.description', {
                            exts: '.mp3'
                          })
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-survey.create.form.wrongEntrySound.label')"
                  :info="$t('call-survey.create.form.wrongEntrySound.info')"
                  :error="errors[`wrongEntrySound`]"
                >
                  <DropzoneFile
                    readonly
                    v-if="
                      model.wrongAnswerSoundFileName && !model.wrongEntrySound
                    "
                    :file="{
                      name: model.wrongAnswerSoundFileName
                    }"
                    @removeFile="model.wrongAnswerSoundFileName = null"
                  />

                  <Dropzone
                    v-else
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.wrongEntrySound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{
                          $t('call-survey.create.form.wrongEntrySound.title')
                        }}
                      </h3>
                      <div class="subtitle">
                        {{
                          $t('call-survey.create.form.wrongEntrySound.subtitle')
                        }}
                      </div>
                      <div class="description">
                        {{
                          $t(
                            'call-survey.create.form.wrongEntrySound.description',
                            {
                              exts: '.mp3'
                            }
                          )
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
              <div class="col-12 col-lg-6">
                <Field
                  :label="$t('call-survey.create.form.endSound.label')"
                  :info="$t('call-survey.create.form.endSound.info')"
                  :error="errors[`endSound`]"
                >
                  <DropzoneFile
                    readonly
                    v-if="model.endSoundFileName && !model.endSound"
                    :file="{
                      name: model.endSoundFileName
                    }"
                    @removeFile="model.endSoundFileName = null"
                  />

                  <Dropzone
                    v-else
                    :options="{
                      paramName: 'file',
                      acceptedFiles: '.mp3',
                      maxFiles: 1
                    }"
                    v-model="model.endSound"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        {{ $t('call-survey.create.form.endSound.title') }}
                      </h3>
                      <div class="subtitle">
                        {{ $t('call-survey.create.form.endSound.subtitle') }}
                      </div>
                      <div class="description">
                        {{
                          $t('call-survey.create.form.endSound.description', {
                            exts: '.mp3'
                          })
                        }}
                      </div>
                    </div>
                  </Dropzone>
                </Field>
              </div>
            </div>
          </div>

          <div class="mb-4 questions">
            <div
              class="d-flex justify-content-between align-items-center my-2 head"
            >
              <h4 class="mb-2 m t-0">
                {{ $t('call-survey.create.form.titles.questions') }}
              </h4>
            </div>

            <div class="row mb-2">
              <div class="col-12 col-lg-4 col-md-6">
                <Field
                  :label="
                    $t('call-survey.create.form.max-question-attempts.label')
                  "
                  :error="errors[`maxQuestionAttempts`]"
                  :info="
                    $t('call-survey.create.form.max-question-attempts.info')
                  "
                >
                  <b-form-input
                    class="border"
                    v-model="model.maxQuestionAttempts"
                    type="number"
                    min="1"
                    @keydown="onlyAcceptNumbers"
                    disabled
                  />
                </Field>
              </div>
            </div>

            <div class="row">
              <div
                class="col-12 col-lg-4"
                v-for="(question, idx) in model.questions"
                :key="question.id"
              >
                <div class="question">
                  <div class="head">
                    <Field
                      className="flex-1"
                      :label="
                        $t('call-survey.create.form.questions.answerType.label')
                      "
                      :info="
                        $t('call-survey.create.form.questions.answerType.info')
                      "
                      :error="errors[`questions[${idx}].answerType`]"
                    >
                      <b-form-select
                        class="border"
                        v-model="question.answerType"
                        :options="answerTypes"
                        disabled
                      ></b-form-select>
                    </Field>
                  </div>
                  <Field
                    :label="$t('call-survey.create.form.questions.sound.label')"
                    :info="$t('call-survey.create.form.questions.sound.info')"
                    :error="errors[`questions[${idx}].sound`]"
                  >
                    <DropzoneFile
                      readonly
                      v-if="question.soundFileName && !question.sound"
                      :file="{
                        name: question.soundFileName
                      }"
                      @removeFile="question.soundFileName = null"
                    />

                    <Dropzone
                      v-else
                      :options="{
                        paramName: 'file',
                        acceptedFiles: '.mp3',
                        maxFiles: 1
                      }"
                      v-model="question.sound"
                    >
                      <div class="dropzone-custom-content">
                        <h3 class="dropzone-custom-title">
                          {{
                            $t('call-survey.create.form.questions.sound.title')
                          }}
                        </h3>
                        <div class="subtitle">
                          {{
                            $t(
                              'call-survey.create.form.questions.sound.subtitle'
                            )
                          }}
                        </div>
                        <div class="description">
                          {{
                            $t(
                              'call-survey.create.form.questions.sound.description',
                              {
                                exts: '.mp3'
                              }
                            )
                          }}
                        </div>
                      </div>
                    </Dropzone>
                  </Field>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-4 time-slots">
            <h4 class="mt-0 mb-2">
              {{ $t('call-survey.create.form.titles.timeSlots') }}
            </h4>
            <Field
              :label="$t('call-survey.create.form.timeSlots.time-zone.label')"
              :info="$t('call-survey.create.form.timeSlots.time-zone.info')"
            >
              <b-form-select
                class="border"
                :options="timezones"
                disabled
                v-model="model.timezone"
                :allowEmpty="false"
              />
            </Field>
            <div class="row time-slots-container">
              <div
                v-for="(timeSlot, idx) in model.timeSlots"
                :key="timeSlot.id"
                class="align-items-start col-12 row"
              >
                <div class="col-11 row">
                  <div class="col-12 col-md-6">
                    <Field
                      :label="
                        $t('call-survey.create.form.timeSlots.from.label')
                      "
                      :info="$t('call-survey.create.form.timeSlots.from.info')"
                      :error="errors[`timeSlots[${idx}].fromTime`]"
                    >
                      <vuestic-date-picker
                        class="form-control pl-2"
                        v-model="timeSlot.fromTime"
                        :placeholder="
                          $t(
                            'call-survey.create.form.timeSlots.from.placeholder'
                          )
                        "
                        :config="timePickerConfig"
                        disabled
                      />
                    </Field>
                  </div>
                  <div class="col-12 col-md-6">
                    <Field
                      :label="$t('call-survey.create.form.timeSlots.to.label')"
                      :info="$t('call-survey.create.form.timeSlots.to.info')"
                      :error="errors[`timeSlots[${idx}].toTime`]"
                    >
                      <vuestic-date-picker
                        class="form-control pl-2"
                        v-model="timeSlot.toTime"
                        :placeholder="
                          $t('call-survey.create.form.timeSlots.to.placeholder')
                        "
                        :config="timePickerConfig"
                        disabled
                      />
                    </Field>
                  </div>
                </div>
              </div>
              <p class="mx-3 text-danger">
                {{ errors[`timeSlots`] }}
              </p>
            </div>
          </div>

          <div class="mb-4 callers">
            <h4 class="mt-0 mb-2">
              {{ $t('call-survey.create.form.titles.callerIds') }}
            </h4>
            <div class="callers-container row">
              <div
                v-for="(caller, idx) in model.callers"
                :key="caller.id"
                class="align-items-start col-12 row"
              >
                <div class="col-11 row">
                  <div class="col-12 col-md-6">
                    <Field
                      :label="
                        $t(
                          'call-survey.create.form.callerIds.destination.label'
                        )
                      "
                      :info="
                        $t('call-survey.create.form.callerIds.destination.info')
                      "
                      :error="errors[`callers[${idx}].destination`]"
                    >
                      <b-form-select
                        class="border"
                        @change="onCountryChange($event, idx, caller.id)"
                        v-model="model.callers[idx].destination"
                        disabled
                      >
                        <b-form-select-option
                          v-for="country in countries"
                          :key="country.code"
                          :value="country.code"
                          :disabled="
                            model.callers[idx].destination !== country.code &&
                              selectedCountries.includes(country.code)
                          "
                        >
                          {{ country.emoji }} {{ country.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </Field>
                  </div>

                  <div class="col-12 col-md-6">
                    <Field
                      :label="
                        $t('call-survey.create.form.callerIds.callerId.label')
                      "
                      :info="
                        $t('call-survey.create.form.callerIds.callerId.info')
                      "
                      :error="errors[`callers[${idx}].callerNumber`]"
                    >
                      <b-form-select
                        class="border"
                        v-model="model.callers[idx].callerNumber"
                        :options="callerNumbers"
                        disabled
                      />
                    </Field>
                  </div>
                </div>
              </div>

              <p class="mx-3 text-danger">
                {{ errors[`callers`] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Field from '../../../elements/Field.vue'
import Dropzone from '../../../elements/Dropzone.vue'
import { onlyAcceptNumbers, removeNullEntries } from '../../../utils/utils'
import { array, mixed, number, object, string } from 'yup'
import vocabService from '../../../services/vocab.service.js'
import { yupToKV } from '../../../utils/yup.js'
import CallSurveyService from '../../../services/call-survey.service.js'
import momentTimezone from 'moment-timezone'
import DropzoneFile from '../../../elements/DropzoneFile.vue'
import timezones from '../../../constants/timezones.json'

const defaultQuestion = {
  id: new Date().getTime(),
  sound: null,
  answerType: ''
}
const defaultTimeSlot = {
  id: new Date().getTime(),
  fromTime: '',
  toTime: ''
}
const defaultCaller = {
  id: new Date().getTime(),
  destination: '',
  callerNumber: ''
}

export default {
  name: 'SurveyDetails',
  components: {
    Field,
    Dropzone,
    DropzoneFile
  },
  data() {
    return {
      questions: [],
      timePickerConfig: {
        wrap: true,
        altFormat: 'H:i',
        dateFormat: 'H:i',
        enableSeconds: false,
        enableTime: true,
        noCalendar: true
      },
      answerTypes: [
        { text: '1 - 5', value: 'one_five' },
        { text: '1 - 10', value: 'one_ten' },
        { text: 'yes or no', value: 'yes_no' }
      ],
      model: {
        name: '',
        trialsCount: 1,
        concurrencyCalls: 1,
        delayMinutesBetweenTrials: 5,
        dtmfTimeout: 5,
        allowDTMFInputDuringPlayback: false,
        timezone: '',
        startSound: null,
        endSound: null,
        wrongEntrySound: null,
        callers: [{ ...defaultCaller }],
        maxQuestionAttempts: 1,
        questions: [{ ...defaultQuestion }],
        timeSlots: [{ ...defaultTimeSlot }]
      },
      errors: {},
      countries: [],
      selectedCountries: [],
      callerNumbers: [],
      loading: false
    }
  },
  computed: {
    timezones() {
      return timezones
    },
    schema() {
      return object().shape({
        name: string()
          .trim()
          .required(this.$t('call-survey.create.form.name.required')),
        trialsCount: number()
          .min(1, this.$t('call-survey.create.form.trials-count.min'))
          .max(10, this.$t('call-survey.create.form.trials-count.max'))
          .required(this.$t('call-survey.create.form.trials-count.required')),
        concurrencyCalls: number()
          .min(1, this.$t('call-survey.create.form.concurrencyCalls.min'))
          .max(10, this.$t('call-survey.create.form.concurrencyCalls.max'))
          .required(
            this.$t('call-survey.create.form.concurrencyCalls.required')
          ),
        delayMinutesBetweenTrials: number()
          .min(
            5,
            this.$t(
              'call-survey.create.form.delay-minutes-between-trials.min',
              { min: 5 }
            )
          )
          .required(
            this.$t(
              'call-survey.create.form.delay-minutes-between-trials.required'
            )
          ),
        dtmfTimeout: number()
          .min(
            5,

            this.$t('call-survey.create.form.dtmf-timeout.min', { min: 5 })
          )
          .required(this.$t('call-survey.create.form.dtmf-timeout.required')),
        startSound: mixed().required(
          this.$t('call-survey.create.form.startSound.required')
        ),
        endSound: mixed().required(
          this.$t('call-survey.create.form.endSound.required')
        ),
        wrongEntrySound: mixed().required(
          this.$t('call-survey.create.form.wrongEntrySound.required')
        ),
        maxQuestionAttempts: number()
          .min(
            1,
            this.$t('call-survey.create.form.max-question-attempts.min', {
              min: 1
            })
          )
          .required(
            this.$t('call-survey.create.form.max-question-attempts.required')
          ),
        questions: array()
          .of(
            object().shape({
              sound: mixed().required(
                this.$t('call-survey.create.form.questions.sound.required')
              ),
              answerType: string()
                .trim()
                .required(
                  this.$t(
                    'call-survey.create.form.questions.answerType.required'
                  )
                )
            })
          )
          .min(1, this.$t('call-survey.create.form.questions.min')),
        timeSlots: array()
          .of(
            object().shape({
              fromTime: string()
                .trim()
                .required(
                  this.$t('call-survey.create.form.timeSlots.from.required')
                ),
              toTime: string()
                .trim()
                .test(
                  'is-greater',
                  this.$t(
                    'call-survey.create.form.timeSlots.to.greaterThanFrom'
                  ),
                  function(value) {
                    const { fromTime } = this.parent
                    return fromTime && value > fromTime
                  }
                )
                .required(
                  this.$t('call-survey.create.form.timeSlots.to.required')
                )
            })
          )
          .min(1, this.$t('call-survey.create.form.timeSlots.min')),
        callers: array()
          .of(
            object().shape({
              destination: string()
                .trim()
                .required(
                  this.$t(
                    'call-survey.create.form.callerIds.destination.required'
                  )
                ),
              callerNumber: string()
                .trim()
                .required(
                  this.$t('call-survey.create.form.callerIds.callerId.required')
                )
            })
          )
          .min(1, this.$t('call-survey.create.form.callerIds.min'))
      })
    },
    timezone() {
      let timezone = momentTimezone.tz.guess()
      const regex = new RegExp('^[a-zA-Z_]+\\/[a-zA-Z_]+$')
      if (!regex.test(timezone)) {
        timezone = 'Asia/Riyadh'
      }
      return timezone
    }
  },
  methods: {
    onlyAcceptNumbers,
    onAddQuestion() {
      this.model.questions.push({
        ...defaultQuestion,
        id: new Date().getTime()
      })
    },
    onRemoveQuestion(idx) {
      this.model.questions.splice(idx, 1)
      this.errors[`questions[${idx}].sound`] = null
      this.errors[`questions[${idx}].answerType`] = null
    },
    // handlers
    handleAddCaller() {
      this.model.callers.push({
        ...defaultCaller,
        id: new Date().getTime()
      })
    },
    handleRemoveCaller(index) {
      const caller = this.model.callers[index]
      this.selectedCountries = this.selectedCountries.filter(
        country => country !== caller.destination
      )

      this.model.callers.splice(index, 1)
      this.errors[`callers[${index}].destination`] = null
      this.errors[`callers[${index}].callerNumber`] = null
    },
    onCountryChange(value, idx) {
      const temp = [...this.selectedCountries]
      temp[idx] = value
      this.selectedCountries = temp
    },
    handleAddTimeSlot() {
      this.model.timeSlots.push({
        ...defaultTimeSlot,
        id: new Date().getTime()
      })
    },
    handleRemoveTimeSlot(index) {
      this.model.timeSlots.splice(index, 1)

      this.errors[`timeSlots[${index}].fromTime`] = null
      this.errors[`timeSlots[${index}].toTime`] = null
    },
    // api calls
    async getCountries() {
      const countries = await vocabService.getCountries()
      this.countries = countries
    },
    async getCallerNumbers() {
      const callerNumbers = await vocabService.getCallerIds()
      this.callerNumbers = callerNumbers.map(number => ({
        text: number,
        value: number
      }))
    },
    async getSurveyDetails() {
      const res = await CallSurveyService.getOneSurvey(this.$route.params.id)
      const survey = res.data.survey
      this.model = {
        ...this.model,
        ...survey,
        callers: survey.callers.map(caller => ({
          ...caller,
          destination: caller.destination,
          callerNumber: caller.callerNumber
        })),
        questions: survey.questions.map(question => ({
          ...question,
          sound: null,
          answerType: question.type
        })),
        timeSlots: survey.timeSlots.map(timeSlot => ({
          ...timeSlot,
          fromTime: timeSlot.fromTime,
          toTime: timeSlot.toTime
        }))
      }
    },
    async onSubmit() {
      try {
        this.loading = true
        this.errors = {}
        // get the data and remove all keys that's null
        const data = removeNullEntries({
          ...this.model,
          timezone: this.timezone
        })

        await this.schema.validate(data, { abortEarly: false }).catch(err => {
          this.errors = yupToKV(err)
        })

        if (!this.schema.isValidSync(data)) {
          return
        }

        // upload files
        const startSoundPromise = CallSurveyService.uploadSurveySound(
          this.model.startSound[0]
        )
        const wrongEntrySoundPromise = CallSurveyService.uploadSurveySound(
          this.model.wrongEntrySound[0]
        )
        const endSoundPromise = CallSurveyService.uploadSurveySound(
          this.model.endSound[0]
        )

        const questionsSoundPromises = this.model.questions.map(question =>
          CallSurveyService.uploadSurveySound(question.sound[0])
        )

        const [
          startSoundRes,
          wrongEntrySoundRes,
          endSoundRes,
          ...questionsSoundRes
        ] = await Promise.all([
          startSoundPromise,
          wrongEntrySoundPromise,
          endSoundPromise,
          ...questionsSoundPromises
        ])

        const modelData = {
          ...data,
          startSoundFileName: startSoundRes.data.originalName,
          startSoundFilePath: startSoundRes.data.path,
          endSoundFileName: endSoundRes.data.originalName,
          endSoundFilePath: endSoundRes.data.path,
          wrongAnswerSoundFileName: wrongEntrySoundRes.data.originalName,
          wrongAnswerSoundFilePath: wrongEntrySoundRes.data.path,
          questions: data.questions.map((question, idx) => ({
            soundFileName: questionsSoundRes[idx].data.originalName,
            soundFilePath: questionsSoundRes[idx].data.path,
            type: question.answerType
          }))
        }

        await CallSurveyService.createSurvey(modelData)
        this.toast(this.$t('call-survey.create.toasts.survey-created'))
        this.$router.push({ name: 'call-survey' })
      } catch (err) {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message
        console.error(errorMessage)
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  },
  mounted() {
    this.getCountries()
    this.getCallerNumbers()
    this.getSurveyDetails()
    this.model.timezone = this.timezone
  }
}
</script>

<style scoped lang="scss">
.questions {
  .question {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;

    .head {
      display: flex;
      align-items: center;
      .field {
        flex: 1;
      }
      gap: 0.5rem;
      button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        margin-top: 1.5rem;
      }
    }
  }
}
</style>
