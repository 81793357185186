<template>
  <div class="page" id="web-call-credentials-list">
    <div class="d-flex flex-row justify-content-between mb-2 p-4 card">
      <h3 class="m-0">
        {{ $t('developer-tab.web-call-credentials.title') }}
      </h3>
      <b-button class="px-3 py-2" variant="primary" @click="createCredential">
        {{ $t('developer-tab.web-call-credentials.generate') }}
      </b-button>
    </div>
    <data-table
      ref="webCallCredentials"
      :data="webCallCredentials"
      :fields="translatedFields"
      :isLoading="credentialsIsLoading"
      :pagination="pagination"
    >
      <template slot="id" slot-scope="props">
        <div
          class="copy-btn"
          role="button"
          @click="copy(escapedScriptTag.replace('{id}', props.rowData.id))"
        >
          <span class="api-key">
            {{ props.rowData.id }}
          </span>
          <i class="fa fa-copy"></i>
        </div>
      </template>
      <template slot="domains" slot-scope="props">
        <b-badge
          v-for="domain in props.rowData.domains"
          :key="domain"
          variant="primary"
          class="mx-1"
        >
          {{ domain }}
        </b-badge>
      </template>
      <template slot="serviceEnabled" slot-scope="props">
        <b-badge
          variant="success"
          v-if="props.rowData.serviceEnabled"
          class="px-2 py-1"
        >
          {{ $t('developer-tab.web-call-credentials.table.enabled') }}
        </b-badge>
        <b-badge variant="danger" v-else class="px-2 py-1">
          {{ $t('developer-tab.web-call-credentials.table.disabled') }}
        </b-badge>
      </template>
      <template slot="actions" slot-scope="props">
        <div class="d-flex actions">
          <b-button
            class="px-3 py-2"
            variant="info"
            @click="enableCredential(props.rowData.id)"
            v-if="!props.rowData.serviceEnabled"
            :disabled="isEnabling[props.rowData.id]"
          >
            {{
              isEnabling[props.rowData.id]
                ? 'loading...'
                : $t('developer-tab.web-call-credentials.table.enable')
            }}
          </b-button>
          <b-button
            class="px-3 py-2"
            variant="warning"
            @click="disableCredential(props.rowData.id)"
            v-if="props.rowData.serviceEnabled"
            :disabled="isDisabling[props.rowData.id]"
          >
            {{
              isDisabling[props.rowData.id]
                ? 'loading...'
                : $t('developer-tab.web-call-credentials.table.disable')
            }}
          </b-button>
          <router-link
            class="px-3 py-2 btn btn-primary"
            @click="editCredential(props.rowData.id)"
            :to="{
              name: 'edit-web-call-credentials',
              params: { id: props.rowData.id }
            }"
          >
            {{ $t('developer-tab.web-call-credentials.table.edit') }}
          </router-link>
        </div>
      </template>
    </data-table>
  </div>
</template>

<script>
import WebCallCredentialsService from '../../../../services/webCallCredentials.service'
import DataTable from '../../../../elements/DataTable.vue'

export default {
  name: 'WebCallCredentialsList',
  components: { DataTable },
  data() {
    return {
      webCallCredentials: [],
      credentialsIsLoading: false,
      pagination: {
        perPage: 10
      },
      isEnabling: {},
      isDisabling: {},
      isCreateModalOpen: false,
      newCredential: {}
    }
  },
  mounted() {
    this.fetchWebCallCredentials()
  },
  methods: {
    async fetchWebCallCredentials() {
      try {
        this.credentialsIsLoading = true
        const res = await WebCallCredentialsService.listWebCallCredentials()
        this.webCallCredentials = res.data.apps
      } catch (error) {
        console.error('Failed to fetch web call credentials: ', error)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.fetchError'),
          {
            type: 'error'
          }
        )
      } finally {
        this.credentialsIsLoading = false
      }
    },
    async enableCredential(id) {
      try {
        this.isEnabling[id] = true
        await WebCallCredentialsService.enableWebCallCredential(id)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.enableSuccess')
        )
        this.fetchWebCallCredentials()
      } catch (error) {
        console.error('Failed to enable credential: ', error)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.enableError'),
          {
            type: 'error'
          }
        )
      } finally {
        this.isEnabling[id] = false
      }
    },
    async disableCredential(id) {
      try {
        this.isDisabling[id] = true
        await WebCallCredentialsService.disableWebCallCredential(id)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.disableSuccess')
        )
        this.fetchWebCallCredentials()
      } catch (error) {
        console.error('Failed to disable credential: ', error)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.disableError'),
          {
            type: 'error'
          }
        )
      } finally {
        this.isDisabling[id] = false
      }
    },
    async createCredential() {
      const res = await WebCallCredentialsService.createWebCallCredential()
      this.newCredential.id = res
      this.isCreateModalOpen = true
      this.$router.push({
        name: 'edit-web-call-credentials',
        params: { id: res }
      })
    },
    copy(text) {
      try {
        navigator.clipboard.writeText(text)
        this.toast(this.$t('developer-tab.web-call-credentials.toasts.copy'))
      } catch (error) {
        console.error('Failed to copy: ', error)
        this.toast(
          this.$t('developer-tab.web-call-credentials.toasts.copyError'),
          {
            type: 'error'
          }
        )
      }
    }
  },
  computed: {
    scriptTag() {
      return `&lt;script type="module" src="${process.env.VUE_APP_NEW_API}normal-web-call/bundle/{id}"&gt;&lt;/script&gt;`
    },
    escapedScriptTag() {
      return this.scriptTag.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
    },
    translatedFields() {
      return [
        {
          name: '__slot:id',
          title: this.$t('developer-tab.web-call-credentials.table.appId')
        },
        {
          name: '__slot:domains',
          title: this.$t('developer-tab.web-call-credentials.table.domains')
        },
        {
          name: 'callerId',
          title: this.$t('developer-tab.web-call-credentials.table.callerId')
        },
        {
          name: '__slot:serviceEnabled',
          title: this.$t(
            'developer-tab.web-call-credentials.table.serviceEnabled'
          )
        },
        {
          name: '__slot:actions',
          title: this.$t('developer-tab.web-call-credentials.table.actions')
        }
      ]
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  gap: 10px;
}
.copy-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  gap: 0.5rem;
}
</style>
