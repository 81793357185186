import JsSIP from 'jssip'
import CryptoJS from 'crypto-js'
import { api } from '../axios'

const login = ({ uri, password }) => {
  process.env.NODE_ENV === 'production'
    ? JsSIP.debug.disable()
    : JsSIP.debug.enable('JsSIP:*')
  const socket = new JsSIP.WebSocketInterface(
    process.env.VUE_APP_SOCKET_INTERFACE
  )

  // decrypt password
  const passwordBytes = CryptoJS.AES.decrypt(
    password,
    process.env.VUE_APP_ENCRYPT_SECRET
  )
  const decryptedPassword = passwordBytes.toString(CryptoJS.enc.Utf8)

  const ua = new JsSIP.UA({
    sockets: [socket],
    uri,
    password: decryptedPassword,
    user_agent: 'platform-webrtc'
  })
  ua.start()

  return ua
}

const searchAgentLiveCalls = async phone => {
  const res = await api.get(`agent-calls/live?phone=${phone}`)
  return res.data
}

export default {
  login,
  searchAgentLiveCalls
}
