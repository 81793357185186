<template>
  <div class="page" id="campaign-uncompleted">
    <div
      class="d-flex flex-row flex-wrap justify-content-between align-items-center mb-2 p-4 card"
    >
      <div class="d-flex flex-column">
        <h3 class="mt-0 mb-1">
          {{ $t('auto-dialer.campaign-uncompleted-requests.title') }}
        </h3>
        <p class="mt-0 mb-1">
          {{ campaignDetails.name }}
        </p>
      </div>
      <div class="d-flex flex-column">
        <p class="mt-0 mb-1 text-primary" v-if="pagination.totalItems > 0">
          <b-badge variant="primary" class="p-2 min-w-0">
            <h6 class="m-0">({{ pagination.totalItems }})</h6>
          </b-badge>
          <b> {{ $t('auto-dialer.campaign-uncompleted-requests.title') }} </b>
        </p>
        <b-button
          variant="primary"
          class="px-3 py-2"
          @click="downloadUncompletedRequests"
          :disabled="uncompletedRequests.length <= 0"
        >
          {{
            $t(
              'auto-dialer.campaign-uncompleted-requests.table.actions.download'
            )
          }}
        </b-button>
      </div>
    </div>
    <data-table
      ref="campaignUncompletedRequestsTable"
      :data="uncompletedRequests"
      :fields="translatedTableFields"
      :pagination="pagination"
      :loading="isLoading"
      @changePage="onPageChange($event)"
    >
    </data-table>
  </div>
</template>

<script>
import AutoDialerService from '../../../services/autoDialer.service'
import DataTable from '../../../elements/Table.vue'

export default {
  name: 'campaign-uncompleted',
  components: {
    DataTable
  },
  data() {
    return {
      isLoading: false,
      uncompletedRequests: [],
      campaignDetails: {},
      pagination: {
        page: 1,
        totalPages: 0
      }
    }
  },
  computed: {
    campaignId() {
      return this.$route.params.id
    },
    translatedTableFields() {
      return [
        {
          accessor: 'phone',
          header: this.$t(
            'auto-dialer.campaign-uncompleted-requests.table.columns.phone'
          )
        },
        {
          accessor: 'name',
          header: this.$t(
            'auto-dialer.campaign-uncompleted-requests.table.columns.name'
          )
        },
        {
          accessor: 'remainingTrials',
          header: this.$t(
            'auto-dialer.campaign-uncompleted-requests.table.columns.remainingTrials'
          )
        }
      ]
    }
  },
  methods: {
    async fetchCampaignDetails() {
      try {
        const res = await AutoDialerService.getCampaign(this.campaignId)
        this.campaignDetails = res.data.campaign
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    async fetchCampaignUnCompletedRequests(page = 1) {
      try {
        this.isLoading = true
        const res = await AutoDialerService.getCampaignUnCompletedRequests(
          this.campaignId,
          page,
          10
        )
        const { requests, ...pagination } = res.data
        this.uncompletedRequests = requests
        this.pagination = { ...this.pagination, ...pagination }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    onPageChange(page) {
      this.pagination.page = page
      this.fetchCampaignUnCompletedRequests(page)
      this.$router.push({ query: { page } })
    },
    async downloadUncompletedRequests() {
      try {
        await AutoDialerService.downloadUnCompletedRequests(this.campaignId)
        this.toast(
          this.$t(
            'auto-dialer.campaign-uncompleted-requests.toasts.downloadSuccess'
          ),
          {
            type: 'success'
          }
        )
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    }
  },
  async mounted() {
    this.pagination.page = this.$route.query.page || 1
    this.fetchCampaignUnCompletedRequests()
    this.fetchCampaignDetails()
  }
}
</script>

<style scoped lang="scss">
#campaign-uncompleted {
  .head {
    background-color: #fdfdfa;
    padding: 1rem;
    border-radius: 0.25rem;
  }
  .filters {
    gap: 1rem;
  }
  .filters-actions {
    gap: 1rem;
  }
}

.select {
  max-width: 400px;
}
.min-w-0 {
  min-width: 0;
}
</style>
