<template>
  <div class="bar-chart">
    <vuestic-chart
      :data="chartData"
      type="vertical-bar"
      class="chart"
    ></vuestic-chart>
  </div>
</template>

<script>
import VuesticChart from '../../vuestic-theme/vuestic-components/vuestic-chart/VuesticChart.vue'

export default {
  components: {
    VuesticChart
  },
  name: 'barChart',
  props: {
    labels: {
      type: Array,
      required: true
    },
    datasets: {
      type: Array,
      required: true
    },
    config: {
      type: Object,
      required: false
    }
  },
  computed: {
    chartData() {
      let palette = this.$store.getters.palette
      const colors = [
        palette.primary,
        palette.danger,
        '#f47a00',
        palette.success,
        palette.warning,
        '#55aa11',
        palette.info,
        '#ffe314',
        '#f40000'
      ]

      return {
        labels: [''],
        datasets: this.datasets.map((dataset, idx) => ({
          label: dataset.label,
          backgroundColor: dataset.color || colors[idx],
          data: [dataset.data]
        }))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bar-chart {
  width: 100%;
  .chart {
    width: 100%;
    height: 240px;
  }
}
</style>
