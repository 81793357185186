<template>
  <div class="survey-analytics">
    <div class="user-responses mb-4">
      <div class="responses row col-12">
        <div
          class="question col-12 p-2"
          v-for="(response, idx) in userResponses"
          :key="idx"
        >
          <div class="border p-2">
            <h3 class="mt-0 mb-2">
              {{ $t('call-survey.survey-analytics.question') }}
              {{ response.idx + 1 }}
            </h3>

            <div class="row">
              <div class="col-12 col-lg-4">
                <p class="m-0">
                  <b>
                    {{ $t('call-survey.survey-analytics.total-responses') }}:</b
                  >
                  {{ npsData[idx].totalResponses }}
                </p>
              </div>
              <div class="col-12 col-lg-4">
                <p class="m-0">
                  <b> {{ $t('call-survey.survey-analytics.nps') }}: </b>
                  {{ normalizePercent(npsData[idx].npsScore) }}
                </p>
              </div>
              <div class="col-12 col-lg-4">
                <p class="m-0">
                  <b>{{ $t('call-survey.survey-analytics.question-type') }}:</b>
                  {{ questionTypes[npsData[idx].questionType] }}
                </p>
              </div>
              <div
                class="d-flex align-items-center col-12 col-lg-4"
                :id="`promoters-info-${idx}`"
              >
                <img
                  class="icon"
                  src="../../../assets/icons/promoters.svg"
                  alt=""
                />
                <p class="m-0">
                  <b>
                    {{ $t('call-survey.survey-analytics.promoterPercentage') }}:
                  </b>
                  {{ normalizePercent(npsData[idx].promoterPercentage) }} %
                </p>
                <i class="fa fa-info-circle mx-2"></i>
                <b-tooltip :target="`promoters-info-${idx}`" triggers="hover">
                  {{ $t('call-survey.survey-analytics.promotersInfo') }}
                </b-tooltip>
              </div>
              <div
                class="d-flex align-items-center col-12 col-lg-4"
                :id="`detractors-info-${idx}`"
              >
                <img
                  class="icon"
                  src="../../../assets/icons/detractors.svg"
                  alt=""
                />
                <p class="m-0">
                  <b>
                    {{
                      $t('call-survey.survey-analytics.detractorPercentage')
                    }}:
                  </b>
                  {{ normalizePercent(npsData[idx].detractorPercentage) }} %
                  <i class="fa fa-info-circle mx-2"></i>
                  <b-tooltip
                    :target="`detractors-info-${idx}`"
                    triggers="hover"
                  >
                    {{ $t('call-survey.survey-analytics.detractorsInfo') }}
                  </b-tooltip>
                </p>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-12 col-lg-6">
                <PieChart
                  :config="{}"
                  :labels="response.labels"
                  :datasets="response.datasets"
                />
              </div>
              <div class="col-12 col-lg-6">
                <BarChart
                  :config="{}"
                  :labels="[]"
                  :datasets="npsData[idx].dataset"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from '../../../elements/charts/PieChart.vue'
import BarChart from '../../../elements/charts/BarChart.vue'

export default {
  components: {
    BarChart,
    PieChart
  },
  data() {
    return {
      questionTypes: {
        one_five: '1 - 5',
        one_ten: '1 - 10',
        zero_nine: '1 - 10',
        yes_no: 'yes or no'
      }
    }
  },
  props: {
    metrics: {
      type: Object,
      required: true
    },
    surveyId: {
      type: Number,
      required: true
    }
  },
  computed: {
    userResponses() {
      return this.metrics.userResponses.map(question => {
        const responses = question.responses
        const labels = responses.map(response => response.userResponse)
        const datasets = responses.map(response => response.count)
        return { idx: question.questionIndex, labels, datasets }
      })
    },
    npsData() {
      return this.metrics.questionsNPS.map(question => ({
        ...question,
        dataset: [
          {
            label: this.$t('call-survey.survey-analytics.promoters'),
            data: question.promoters
          },
          {
            label: this.$t('call-survey.survey-analytics.detractors'),
            data: question.detractors
          },
          {
            label: this.$t('call-survey.survey-analytics.passives'),
            data: question.passives
          }
        ]
      }))
    }
  },
  methods: {
    normalizePercent(percent) {
      return percent.toFixed(2)
    }
  }
}
</script>

<style lang="scss">
.icon {
  width: 2rem;
  height: 2rem;
  margin-inline-end: 0.5rem;
}
</style>
