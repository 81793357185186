var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"survey-cdrs"}},[_c('div',{staticClass:"card p-4 filters mb-2"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-4"},[_c('Field',{attrs:{"label":_vm.$t('call-survey.survey-cdrs.filters.phone.label')}},[_c('b-form-input',{staticClass:"border",attrs:{"placeholder":_vm.$t('call-survey.survey-cdrs.filters.phone.placeholder'),"type":"number"},on:{"keydown":_vm.onlyAcceptNumbers},model:{value:(_vm.filters.phone),callback:function ($$v) {_vm.$set(_vm.filters, "phone", $$v)},expression:"filters.phone"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('Field',{attrs:{"label":_vm.$t('call-survey.survey-cdrs.filters.status.label')}},[_c('Select',{staticClass:"select",attrs:{"multiple":"","searchable":"","placeholder":_vm.$t('call-survey.survey-cdrs.filters.status.placeholder'),"options":_vm.statusOptions,"preselectFirst":false},model:{value:(_vm.filters.statuses),callback:function ($$v) {_vm.$set(_vm.filters, "statuses", $$v)},expression:"filters.statuses"}})],1)],1),_c('div',{staticClass:"col-md-4"},[_c('Field',{attrs:{"label":_vm.$t('call-survey.survey-cdrs.filters.completion-status.label')}},[_c('Select',{staticClass:"select",attrs:{"multiple":"","searchable":"","placeholder":_vm.$t(
                'call-survey.survey-cdrs.filters.completion-status.placeholder'
              ),"options":_vm.completionStatusOptions,"preselectFirst":false},model:{value:(_vm.filters.completionStatuses),callback:function ($$v) {_vm.$set(_vm.filters, "completionStatuses", $$v)},expression:"filters.completionStatuses"}})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"px-3 py-2",attrs:{"variant":"primary"},on:{"click":_vm.onFilter}},[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.filter'))+" ")])],1)]),_c('data-table',{ref:"surveyCallRequestsTable",attrs:{"data":_vm.cdrs,"fields":_vm.translatedTableCdrsFields,"pagination":_vm.pagination,"loading":_vm.isLoading},on:{"changePage":function($event){return _vm.onPageChange($event)}},scopedSlots:_vm._u([{key:"status",fn:function({ row }){return [_c('b-badge',{staticClass:"px-3 py-2",style:({
          backgroundColor: _vm.cdrsStatusColors[row.status] || '#ccc'
        })},[_vm._v(" "+_vm._s(_vm.$t(`call-survey.survey-cdrs.statusOptions.${row.status}`))+" ")])]}},{key:"completionStatus",fn:function({ row }){return [_c('b-badge',{staticClass:"px-3 py-2",style:({
          backgroundColor:
            _vm.cdrsCompletionStatusColors[row.completionStatus] || '#ccc'
        })},[_vm._v(" "+_vm._s(_vm.$t( `call-survey.survey-cdrs.completionStatusOptions.${row.completionStatus}` ))+" ")])]}},{key:"recordingLink",fn:function({ row }){return [(row.recordingLink)?_c('b-button',{staticClass:"px-3 py-2",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){_vm.currentRecordingLink = row.recordingLink
          _vm.showRecordingModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.table.listen'))+" ")]):_vm._e()]}},{key:"agentConnectedAt",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.agentConnectedAt))+" ")]}},{key:"customerConnectedAt",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm._f("formatDate")(row.customerConnectedAt))+" ")]}},{key:"actions",fn:function({ row }){return [(row.completionStatus !== 'no-response')?_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"px-3 py-2",attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){_vm.showAnswersModal = true
            _vm.currentCDR = row}}},[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.table.answers'))+" ")])],1):_vm._e()]}}])}),_c('b-modal',{attrs:{"title":_vm.$t('auto-dialer.campaign-cdrs.modals.recording.title'),"scrollable":"","content-class":"overflow-auto","hide-footer":"","centered":"","dir":_vm.dir,"ignore-enforce-focus-selector":"textarea, button, input"},model:{value:(_vm.showRecordingModal),callback:function ($$v) {_vm.showRecordingModal=$$v},expression:"showRecordingModal"}},[_c('audio',{staticClass:"w-100",attrs:{"controls":"","src":_vm.currentRecordingLink}})]),_c('b-modal',{attrs:{"title":_vm.$t('call-survey.survey-cdrs.modals.answers.title'),"scrollable":"","content-class":"overflow-auto","hide-footer":"","centered":"","ignore-enforce-focus-selector":"textarea, button, input","dir":_vm.dir},model:{value:(_vm.showAnswersModal),callback:function ($$v) {_vm.showAnswersModal=$$v},expression:"showAnswersModal"}},[_c('div',{staticClass:"answers"},_vm._l((_vm.currentCDR.answers),function(answer,answerIdx){return _c('div',{key:answerIdx,staticClass:"answer mb-3 card p-2"},[_c('h5',{staticClass:"m-0 mb-1"},[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.question'))+" "+_vm._s(answer.questionIndex + 1)+" ")]),_c('p',{staticClass:"m-0 mb-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.attempt-number'))+": ")]),_vm._v(_vm._s(answer.attemptNumber)+" ")]),_c('p',{staticClass:"m-0 mb-1"},[_c('b',[_vm._v(_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.question-type'))+": ")]),_vm._v(" "+_vm._s(_vm.questionTypes[answer.questionType])+" ")]),_c('div',{staticClass:"user-response"},[_c('p',{staticClass:"my-0"},[_c('small',[_vm._v(" "+_vm._s(_vm.$t('call-survey.survey-cdrs.modals.answers.user-response'))+" :")])]),(answer.questionType === 'yes_no')?_c('b-button',{staticClass:"px-3 py-2 mb-2",style:({
              color: 'white',
              backgroundColor:
                parseInt(answer.userResponse) === 1 ? '#66BB6A' : '#FF6B6B'
            }),attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(parseInt(answer.userResponse) === 1 ? 'Yes' : 'No')+" ")]):(answer.questionType === 'one_five')?_c('b-form-rating',{staticClass:"px-2 mb-2",attrs:{"value":+answer.userResponse,"variant":"warning","readonly":"","size":"lg","inline":"","show-value":""}}):_c('b-button',{staticClass:"px-3 py-2 mb-2",style:({
              color: 'white',
              backgroundColor: '#888'
            }),attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(answer.userResponse)+" ")])],1)])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }