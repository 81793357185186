import { render, staticRenderFns } from "./IncomingCallPad.vue?vue&type=template&id=3a31cdea&scoped=true"
import script from "./IncomingCallPad.vue?vue&type=script&lang=js"
export * from "./IncomingCallPad.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a31cdea",
  null
  
)

export default component.exports