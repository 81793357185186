import { api } from '../axios'

const listWebCallCredentials = async () => {
  const res = await api.get('/normal-web-call/list')
  return res.data
}

const createWebCallCredential = async () => {
  const res = await api.post('/normal-web-call/init')
  return res.data
}

const getWebCallCredentials = async id => {
  const res = await api.get(`/normal-web-call/${id}`)
  return res.data
}

const getJSBundle = async id => {
  const res = await api.get(`/normal-web-call/bundle/${id}`)
  return res.data
}

const editWebCallCredentials = async (id, data) => {
  const res = await api.put(`/normal-web-call/${id}`, data)
  return res.data
}

const assignAllowedDomains = async (id, data) => {
  const res = await api.patch(
    `/normal-web-call/${id}/assign-allowed-domains`,
    data
  )
  return res.data
}

const enableWebCallCredential = async id => {
  const res = await api.patch(`/normal-web-call/${id}/enable`)
  return res.data
}

const disableWebCallCredential = async id => {
  const res = await api.patch(`/normal-web-call/${id}/disable`)
  return res.data
}

const WebCallCredentialsService = {
  listWebCallCredentials,
  createWebCallCredential,
  getJSBundle,
  editWebCallCredentials,
  assignAllowedDomains,
  enableWebCallCredential,
  disableWebCallCredential,
  getWebCallCredentials
}

export default WebCallCredentialsService
