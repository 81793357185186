<template>
  <div class="table-container">
    <div class="table" :style="tableStyle">
      <table class="position-relative bg-primary table-striped">
        <thead>
          <tr class="border-0">
            <th
              class="border-0 text-white"
              v-for="field in fields"
              :key="field.accessor"
            >
              {{ field.header }}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr
            v-if="!loading && !data.length"
            class="align-items-center h-100 text-center tr"
          >
            <td :colspan="fields.length">
              <slot name="empty">
                {{ $t('shared.table.no-data-available') }}
              </slot>
            </td>
          </tr>
          <fragment v-for="row in data" :key="row.id">
            <tr class="tr">
              <td v-for="field in fields" :key="field.accessor" v-bind="field">
                <slot :name="field.accessor" :row="row">
                  {{ row[field.accessor] }}
                </slot>
              </td>
            </tr>
            <tr
              class="details-row"
              :class="activeRowDetailsId !== row.id ? 'd-none' : ''"
            >
              <td :colspan="fields.length">
                <slot name="details" :row="row"></slot>
              </td>
            </tr>
          </fragment>
        </tbody>
        <div
          class="position-absolute d-flex w-100 h-100 overlay-loading"
          v-if="loading"
        >
          <half-circle-spinner
            :animation-duration="1500"
            :size="50"
            color="#008ECF"
            class="m-auto"
          />
        </div>
      </table>
    </div>
    <div
      class="d-flex justify-content-end align-items-center py-4 pagination"
      v-if="pagination"
    >
      <ul class="d-flex flex-wrap p-0 pages">
        <li>
          <button
            :disabled="pagination.page <= 1"
            class="shadow-none btn btn-primary btn-sm hide-not-focused-btn h-100"
            @click="onBefore()"
          >
            <i
              v-if="locale === 'ar'"
              class="fa-chevron-right fa icon"
              aria-hidden="true"
            ></i>
            <i v-else class="fa-chevron-left fa icon" aria-hidden="true"></i>
          </button>
        </li>
        <li v-for="page in pages" :key="page">
          <button
            class="shadow-none rounded-0 btn btn-primary btn-sm hide-not-focused-btn h-100"
            :class="page == pagination.page ? 'focus' : ''"
            @click="changePage(page)"
          >
            {{ page }}
          </button>
        </li>
        <li>
          <button
            :disabled="pagination.page >= pagination.totalPages"
            class="shadow-none btn btn-primary btn-sm hide-not-focused-btn"
            @click="onAfter()"
          >
            <i
              v-if="locale === 'ar'"
              class="fa-chevron-left fa icon"
              aria-hidden="true"
            ></i>
            <i v-else class="fa-chevron-right fa icon" aria-hidden="true"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'
import Vue from 'vue'

export default {
  name: 'data-table',
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      activeRowDetailsId: 0,
      pagesCount: 0
    }
  },
  mounted() {
    if (this.pagination.totalPages) {
      this.pagesCount = this.pagination.totalPages
    } else if (this.pagination.perPage) {
      this.pagesCount = Math.ceil(this.data.length / this.pagination.perPage)
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    paginationMax: {
      type: Number,
      default: () => 6
    },
    tableStyle: {
      type: Object
    }
  },
  computed: {
    locale() {
      return Vue.i18n.locale()
    },
    pages() {
      if (this.pagination.page > this.paginationMax - 2) {
        return new Array(this.paginationMax + 2)
          .fill(0)
          .map(
            (_, idx) => this.pagination.page + idx - (this.paginationMax - 2)
          )
          .filter(
            e => e <= (this.pagination.last_page || this.pagination.totalPages)
          )
      } else {
        return new Array(this.paginationMax + 2)
          .fill(0)
          .map((_, idx) => idx + 1)
          .filter(
            e => e <= (this.pagination.last_page || this.pagination.totalPages)
          )
      }
    }
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page)
    },
    toggleDetails(id) {
      this.activeRowDetailsId = this.activeRowDetailsId === id ? null : id
    },
    onBefore() {
      if (this.pagination.page > 1) {
        this.changePage(this.pagination.page - 1)
      }
    },
    onAfter() {
      if (this.pagination.page < this.pagination.totalPages) {
        this.changePage(this.pagination.page + 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  overflow-x: auto;
  overflow-y: visible;
}
table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
}

table td,
table th {
  border: 1px solid #ebedf3;
  padding: 0.75rem;
  text-align: center;
  font-weight: 400;
}

table {
  @media (max-width: 1440px) {
    th,
    td {
      font-size: 12px;
    }
  }
}

.overlay-loading {
  background: #00000048;
  z-index: 10;
  top: 0;
}

table tbody > tr:not(.details-row):hover {
  background-color: #f5f5f5;
}

table th {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

table tr.details-row {
  background-color: #f5f5f5;
}

.pagination {
  ul {
    display: flex;
    list-style-type: none;
  }
  .icon {
    font-size: 14px;
  }
  .pages {
    li:last-child .hide-not-focused-btn {
      border-start-end-radius: 200px;
      border-end-end-radius: 200px;
    }
    li:first-child .hide-not-focused-btn {
      border-end-start-radius: 200px;
      border-start-start-radius: 200px;
    }
  }
}

.table-striped tbody tr:nth-last-child(4n) {
  background-color: #eff4f5;
}
</style>
